import * as d3 from 'd3'

export interface Node extends d3.SimulationNodeDatum {
    id: string;
    name: string;
    index?: number;
}

export interface Link extends d3.SimulationLinkDatum<Node> {
    source: any;
    target: any;
    sourceKey?: any;
    targetKey?: any;
    value: any;
}

export type Data = {
    nodes: Node[];
    links: Link[];
};

export class GNode {
    val
    children: GNode[] = []
    constructor (val: string) {
        this.val = val;
    }

    add(n: GNode) {
        this.children.push(n);
    }
}

export const NewGraph = (Nodes: Node[], Edges: Link[]): Map<string, GNode> => {
    if (Nodes === null || Nodes === undefined || Nodes.length === 0 || Edges === null || Edges === undefined || Edges.length === 0) {
        return new Map<string, GNode>()
    }
    const nodes = new Map<string, GNode>()
    Nodes.forEach((n) => nodes.set(n.id,  new GNode(n.id)))
    const seenEdges = new Set<string>()
    Edges.forEach(e => {
        const s = typeof e.source === "string" ? e.source : e.source.id
        const t = typeof e.target === "string" ? e.target : e.target.id
        if (!seenEdges.has(s + "-" + t)) {
            nodes.get(s).add(nodes.get(t))
        }
        if (!seenEdges.has(t + "-" + s)) {
            nodes.get(t).add(nodes.get(s))
        }
        seenEdges.add(s + "-" + t)
        seenEdges.add(t + "-" + s)
    }) 
    return nodes
}

interface subMatch {
    path: string[]
    contains: Set<string>
    settled: boolean
}

// This doesn't work very well
export const DoMultiTraversal = (node: GNode, targets: Set<string>): {paths: string[][], containsFragments: boolean} => {
    const rootVal = node.val
    const validPaths = [];
    const targetLength = targets.size
    let containsPathFragments: boolean = false;

    if (!node || targets.size === 0) {
        return {paths: validPaths, containsFragments: false}
    }

    function traverse(node: GNode, path: string[], targets: Set<string>): subMatch[] {
        if(path === null){
            path = [];
        }
        let localSubMatches: subMatch[] = []
        
        const subTargets = new Set<string>(targets)
        const wasAMath = subTargets.delete(node.val)
        if(subTargets.size === 0){
            validPaths.push([...path, node.val]);
            return localSubMatches
        }
        if (!path.includes(node.val)) {
            node.children.forEach( x => {
                var newPath = [...path, node.val]
                const subMatches = traverse(x, newPath, subTargets);
                localSubMatches = [...localSubMatches, ...subMatches]
                // aggregate subMatches into localSubMatches
            });
        }

        // we have hit a subpath
        if (( node.children.length === 1 || path.includes(node.val)) && subTargets.size < targetLength) {
            const matchTargets = new Set<string>()
            targets.forEach(t => {
                if (!subTargets.has(t)) {
                    matchTargets.add(t)
                }
            })
            const sm: subMatch = {
                path: [...path, node.val],
                contains: matchTargets,
                settled: wasAMath
            }
            return [sm]
        }

        if (wasAMath) {
            const missingTargets = new Set<string>(subTargets)
            for (let i = 0; i < localSubMatches.length; i++) {
                if (!localSubMatches[i].settled) {
                    localSubMatches[i].settled = true
                    localSubMatches[i].path = [...path, node.val]
                }
                localSubMatches[i].contains.forEach(t => missingTargets.delete(t))
                if (missingTargets.size === 0) {
                    break
                }
            }
            if (missingTargets.size === 0) {
                containsPathFragments = true
                localSubMatches.forEach(m => validPaths.push(m.path))
                return []
            }
        }
        return localSubMatches
    }
    traverse(node, null, targets);
    return {paths: validPaths, containsFragments: containsPathFragments}
}

export const AllPaths = (node: GNode, target: string): string[][] => {
    const validPaths = [];

    if (!node || !target) {
        return  validPaths
    }

    function traverse(node: GNode, path: string[]) {
        if(path === null){
            path = [];
        }

        if(node.val === target){
            validPaths.push([...path, node.val]);
            return
        }
        if (!path.includes(node.val)) {
            node.children.forEach( x => {
                var newPath = [...path, node.val]
                traverse(x, newPath);
            });
        }
    }
    traverse(node, null);
    return validPaths
}
