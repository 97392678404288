import "./NotFound.css"
import {Helmet} from "react-helmet"

export default function NotFound() {
    return (
        <div className="page blastPage article">
            <Helmet>‍
                <title>Not Found - REBUG</title>‍
                <meta name="description" content="The page you're looking for was not found."/>        
            </Helmet>
            <div className="smallGap"></div>
            <div className="container">
                <h1 className="bigTitle">404 Page Not Found 😮</h1>
            </div>
        </div>
    )
}