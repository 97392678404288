import { useState } from "react";
import { auth } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { isStrongPwd } from "../util/util";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();

    const signup = async (email, password) => {
        setError(null);
        setIsPending(true);

        try {
            if (!isStrongPwd(password)) {
                throw new Error(
                    "Password must be 8-30 characters and must contain one lowercase, one uppercase, one number, and one special character"
                );
            }
            // signup
            const res = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );

            if (!res) {
                throw new Error("Could not complete signup");
            }

            // dispatch login action
            dispatch({ type: "LOGIN", payload: res.user });

            if (!isCancelled) {
                setIsPending(false);
                setError(null);
            }
        } catch (err) {
            if (!isCancelled) {
                setError(err.message);
                setIsPending(false);
            }
        }
    };

    const clearError = () => {
        setError(null);
    };

    return { signup, error, isPending, clearError };
};
