import "./Comment.css"
import CommentList from "./CommentList"
import CounterBadge from "../CountBadge/CounterBadge"
import { useState } from "react"
import CommentForm from "../CommentForm/CommentForm"
import RandUser from "../RandUser/RandUser"
import AuthModal from "../CommentForm/AuthModal"

export interface comment {
    author: string
    date: string
    likes: number
    body: string
    comments?: comment[]

}
interface commentProps {
    comment: comment
    reply: boolean
}

export function Comment({comment, reply}:commentProps) {
    const [showForm, setShowForm] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <div className="comment">
            <AuthModal isOpen={modalOpen} setIsOpen={setModalOpen}/>
            <div className="userIconContainer">
                <div className='userIcon'> <RandUser/> </div>
                <div className="commentBody">
                    <div className="commentBodyBody">
                        <div className="commentBodyHeader">
                            <span className="articleText author">
                                {comment.author}
                                {" / "}
                                <span className="date">{comment.date}</span>
                            </span>
                        </div>
                        <div className="commentBodyContent">
                            <span className="articleText">
                                {comment.body}
                            </span>
                        </div>
                    </div>
                    <div className="commentBodyFooter">
                        <div className="commentFeedback">
                            <button>
                                <CounterBadge icon="favorite" size="small" style="flex" count={0}/>
                            </button>
                            {reply && <span className="divider">{" / "}</span>}
                            {(reply && !showForm) && (
                                <button onClick={() => setShowForm(true)}>
                                    <CounterBadge icon="chat_bubble"  size="small" style="flex" count={0}/>
                                </button>
                            )}
                            {showForm && (
                                <button onClick={() => setShowForm(false)}>
                                    <CounterBadge icon="close" size="small" style="flex" count={0}/>
                                </button>
                            )}
                            {showForm && (
                                <button onClick={() => {setModalOpen(true)}} className="postButton">
                                    POST
                                </button>
                            )}
                        </div>
                        {showForm && (
                            <CommentForm/>
                        )}
                    </div>
                </div>
            </div>
            {comment.comments?.length > 0 && (
                <div className="subComments">
                    <CommentList comments={comment.comments} reply={false}/>
                </div>
            )}
        </div>
    )
}