import "./TalkingHead.css"
import RandUser from "../../components/RandUser/RandUser"
import User from "../../components/RandUser/User"

export default function TalkingHead({quote, color, faceIndex}) {
    return (
        <div className="talkingHead">
            {!(color && faceIndex) && (
                <RandUser/>
            )}
            {(color && faceIndex) && (
                <User color={color} faceIndex={faceIndex}/>
            )}
            <div className="quote">
                <p>"{quote}"</p>
            </div>
        </div>
    )
}