import { useEffect, useState } from "react";
import "./HashIcon.css";

const smileys = [
    ":-)",
    ":-o",
    ">:-D",
    ";-P",
    ":^)",
    ":-T",
    "X-)",
    ":-D",
    "B-)",
    ":-U",
    ";-D",
    ">:-]",
];

const colors = [
    "red", 
    "orange", 
    "yellow", 
    "green", 
    "blue", 
    "purple"
];

const doHash = (text) => {
    let hash = 0;
    for (let i = 0, len = text.length; i < len; i++) {
        let char = text.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0;
    }
    return Math.abs(hash);
};

const cleanHash = (hashNum) => {
    // We need at least three digits
    const paddedHashNum = hashNum
        .toString()
        .padEnd(3, hashNum[0]);
    // return the last three digits as a number
    const lastThree = paddedHashNum
        .slice(hashNum.length - 3, hashNum.length);
    return parseInt(lastThree);
};

export default function HashIcon({ username }) {
    const [hashOne, setHashOne] = useState(null);
    const [hashTwo, setHashTwo] = useState(null);

    useEffect(() => {
        // if no username, do random
        if (username.length === 0) {
            setHashOne(
                Math.floor(
                    Math.random() * (999 - 100 + 1)
                ) + 100
            );
            setHashTwo(
                Math.floor(
                    Math.random() * (999 - 100 + 1)
                ) + 100
            );
        } else {
            // chain doHash into cleanHash into setHash
            setHashOne(cleanHash(doHash(username)));
            setHashTwo(cleanHash(doHash(username + username)));
        }
    }, [username]);

    return (
        <>
            {hashOne !== null && hashTwo !== null && (
                <div className="hash-icon">
                    <div
                        className={`icon-face ${
                            colors[hashOne % colors.length]
                        }`}
                    >
                        {smileys[hashTwo % smileys.length]}
                    </div>
                </div>
            )}
        </>
    );
}
