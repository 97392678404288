import * as d3 from "d3";
import { useEffect, useRef, useState } from 'react';
import { useNetGraph } from "../../hooks/useNetGraph";
import { Link, Node } from "../../util/GraphNode";
import { useRelationalData } from "../../hooks/useRelationalData";
import { useDimensions } from '../../hooks/useDimension';
import { ActivePaths } from "./ActivePaths";
import { TableSet } from './TableSet';
import { Schema } from "./Table";
import './Network.css'
import CodeBox from "../CodeBox/CodeBox";
import { useSchemaContext } from "../../hooks/useSchemaContext";

// TODO drag viewport/pan
export const NetworkDiagram = () => {
    const [SQL, setSQL] = useState("-- SQL will be generated here")
    const [links, setLinks] = useState<Link[]>([]);
    const [nodes, setNodes] = useState<Node[]>([]);
    const {schemas, graphData, schemaToQuery } = useRelationalData();
    const {schemaForm, setSchemaForm} = useSchemaContext();
    const [activeSchemas, setActiveSchemas] = useState((): Schema[] => {
        let s: Schema[]
        return s
    })
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef(null);
    const {width, height} = useDimensions(containerRef)
    const canvas = canvasRef.current;

    useEffect(() => {
        setSchemaForm(activeSchemas)
    },[activeSchemas])

    useEffect(() => {
        setLinks(graphData?.links.map((d) => ({ ...d })))
        setNodes(graphData?.nodes.map((d) => ({ ...d })))
    }, [graphData, width, height])

    const { RADIUS, DrawNetwork, HandleClick, HandleMouseMove, activePaths } = useNetGraph({nodes, links, canvas, width, height})

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if (!context) {
            return;
        }
        // mobile charge: -140
        d3.forceSimulation(nodes)
        .force(
            'link',
            d3.forceLink<Node, Link>(links).id((d) => d.id).distance(75)
        )
        .force('collide', d3.forceCollide().radius(RADIUS))
        .force('charge', d3.forceManyBody().strength(width <= 500 ? -141 : -165))
        .force('center', d3.forceCenter(width / 2, height / 2))
        .on('tick', () => {
            DrawNetwork(context);
        });
    }, [nodes, links]);

    const handlePathSelect = (paths: string[]) => {
        const schemaMap = {}
        schemas.forEach(s => schemaMap[s.TableName] = s) 
        setActiveSchemas(paths.map(p => schemaMap[p]))
    }

    const handleClick = (e) => {
        setActiveSchemas([])
        HandleClick(e)
    }

    const handleSQL = () => {
        const query = schemaToQuery(schemaForm)
        setSQL(_ => query || "-- Try selecting some paths and fields.")
    }

    return (
        <div className="article networkComponent">
                <div ref={containerRef} className="network">
                    <canvas
                        onMouseMove={(e) => HandleMouseMove(e)}
                        onClick={(e) => handleClick(e)}
                        ref={canvasRef}
                        style={{width, height}}
                        width={width}
                        height={height}
                    />
                </div>

                <ActivePaths paths={activePaths} handleSelection={handlePathSelect}/>
                <TableSet schemas={activeSchemas || []}/>
                <button className='deploy' onClick={handleSQL}>CLICK FOR SQL!</button>
                <CodeBox code={"\n"+SQL+"\n\n"} language={"txt"} abbreviated={false}/>
        </div>
    );
};
