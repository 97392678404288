import "./AuthorGrid.css"
import "./PostGrid.css"
import { useBrowseContext } from "../../hooks/useBrowseContext"
import HashIcon from "../RandUser/HashIcon"

export default function AuthorNav() {
    const {page, totalPages, nextPage, prevPage, filter} = useBrowseContext()

    const handleClick = (dir:string) => {
        if (dir === "next" && page < (totalPages - 1)) {
            nextPage()
        } else if (dir === "prev" && page > 0) {
            prevPage()
        }
    }
    
    return (
        <div className={filter ? "grid-nav filtered" : "grid-nav"}>
            <div className="sub-title flex">
                <div className="author-title flex">
                        <span className="flex ai-center jc-center"><HashIcon username={"aaron"}/></span>
                        <span className="title">//AARON//</span>
                </div>
                <div className="buttons">
                    <button className={(page === 0 || filter.length > 0) ? "disabled" : ""} onClick={()=>handleClick("prev")}>
                        <span className="material-symbols-outlined previous">
                            double_arrow
                        </span>
                    </button>
                    <button className="disabled navInfo">
                        <span>
                            {filter.length > 0 ? "1/1" : `${page + 1}`+`/${totalPages}`}
                        </span>
                    </button>
                    <button className={(page === totalPages - 1 || filter.length > 0) ? "disabled" : ""} onClick={()=>handleClick("next")}>
                        <span className="material-symbols-outlined next">
                            double_arrow
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}