import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./Form.css";
import App from "./App";
import { BrowseContextProvider } from "./context/BrowseContext";
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthContextProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain="dev-mn43jwe3s0ade2gk.us.auth0.com"
            clientId="FWkvlCX2lcv9IlJFbc9MbdpiwSSaVrGy"
            authorizationParams={{
            redirect_uri: window.location.origin
            }}
        >
            <AuthContextProvider>
                <BrowseContextProvider>
                    <App />
                </BrowseContextProvider>
            </AuthContextProvider>
        </Auth0Provider>
    </React.StrictMode>
);
