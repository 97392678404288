import "./Footer.css"
import { NavLink } from "react-router-dom"

export default function Footer() {
    return (
        <div className="footer">
            <div className="contact">
                <p>{"Contact --> AARON@REBUG.DEV"}</p>
            </div>
            <div className="footerLinksContainer">
                <div className="footerLinks">
                        <a href="https://www.linkedin.com/in/aaron-hough/" className="fa fa-linkedin"></a>
                        <a href="https://github.com/arrno" className="fa fa-github"></a>
                        <a href="#" className="fa fa-twitter"></a>
                </div>
            </div>
            <div className="coppyright">
                <NavLink to="/terms">Terms of Service</NavLink>
                <span>{"/"}</span>
                <NavLink to="/privacy">Privacy Policy</NavLink>
                <p>Copyright © 2024 REBUG.DEV</p>
            </div>
        </div>
    )
}