import { useEffect, useState } from 'react';
import './ActivePaths.css'
import { nanoid } from 'nanoid';

export const ActivePaths = ({paths, handleSelection}) => {
    const [selectedpath, setSelectedPath] = useState()

    const onClick = (path, id) => {
        setSelectedPath(id)
        handleSelection(path)
    }

    useEffect(() => {
        setSelectedPath(null)
    }, [paths])
    return (
        <div className="activePaths">
            <ul>
                {paths.length === 0 && (
                    <li className="pathInstructions">{"< Select some nodes... >"}</li>
                )}
                {paths.length !== 0 && (
                    <li className="pathInstructions">{"// Choose a path!"}</li>
                )}
                {paths.sort((a,b) => {
                    return a.length > b.length ? 1 : -1
                }).map( (p, i) => {
                    return <li className={selectedpath === i ? "active" : ""} onClick={() => onClick(p, i)} key={nanoid()}>{"> "+p.map(x => ` [ ${x} ]`).join(" --> ")}</li>
                })}
            </ul>
        </div>
    )
};
