import { useEffect, useRef } from "react";
import "./GitTreeMap.css";
import * as d3 from "d3";
import { useDimensions } from "../../hooks/useDimension";
var json = require("./data.json");

const MINWIDTH = 30;
const MINHEIGHT = 40;
const pt_px = 0.75,
    font_size = 20,
    averageLetterWidth = 0.95;

export default function GitTreeMap({}) {
    const containerRef = useRef(null);
    const { width, height } = useDimensions(containerRef);

    useEffect(() => {
        // clear
        d3.selectAll("svg > *").remove();

        const hierarchy = d3
            .hierarchy(json)
            .sum((d: any) => d.value)
            .sort((a, b) => b.value - a.value);

        const treemap = d3.treemap().size([width, height]).padding(3);

        const root = treemap(hierarchy);

        const svg = d3
            .select("svg")
            .classed("out", true)
            .on("mouseover", function () {
                d3.select(this).classed("out", false);
            })
            .on("mouseout", function () {
                d3.select(this).classed("out", true);
            });
        
        // Plot our grid
        svg.selectAll("rect")
            .data(root.leaves())
            .enter()
            .append("rect")
            .attr("x", (d) => d.x0)
            .attr("y", (d) => d.y0)
            .attr("width", (d) => d.x1 - d.x0)
            .attr("height", (d) => d.y1 - d.y0)
            .attr("fill", (d: any) => {
                if (d.data.popularity  === 1 || d.data.popularity === 2 || d.data.popularity === 3) {
                    return "var(--capFlag2)";
                } else if (d.data.popularity === 4 || d.data.popularity === 5 || d.data.popularity === 6) {
                    return "var(--capFlag3)";
                } else if (d.data.popularity === 7) {
                    return "var(--capFlag4)";
                } else if (d.data.popularity === 8) {
                    return "var(--capFlag5)";
                } else {
                    return "var(--capFlag6)";
                }
            })
            .on("mouseover", function () {
                d3.select(this).classed("hover", true);
            })
            .on("mouseout", function () {
                d3.select(this).classed("hover", false);
            });
        
        // Add text
        svg.selectAll("text")
            .data(root.leaves())
            .enter()
            .append("text")
            .attr("x", function (d) {
                return d.x0 + 5;
            })
            .attr("y", function (d) {
                return d.y0 + 20;
            }) 
            .text(function (d: any) {
                return d.data.name;
            })
            .attr("font-size", `${font_size}px`)
            .attr("fill", "var(--consoleWhite)")
            .attr("font-family", "Roboto Mono")
            .attr("font-weight", "600")
            .style("opacity", (d: any) => {
                if (
                    d.data.name.length *
                        averageLetterWidth *
                        pt_px *
                        font_size >=
                    d.x1 - d.x0
                ) {
                    return 0;
                }
                return 1;
            })
            .style("pointer-events", "none");
        
        // Add second line of text
        svg.selectAll("stats")
            .data(root.leaves())
            .enter()
            .append("text")
            .attr("x", function (d) {
                return d.x0 + 5;
            })
            .attr("y", function (d) {
                return d.y0 + 45;
            }) 
            .text(function (d: any) {
                return `${d.data.popularity} \\ ${d.data.value}`;
            })
            .attr("font-size", `16px`)
            .attr("fill", "var(--consoleWhite)")
            .attr("font-family", "Roboto Mono")
            .style("pointer-events", "none")
            .style("opacity", (d: any) => {
                if (
                    d.data.name.length *
                        averageLetterWidth *
                        pt_px *
                        font_size >=
                    d.x1 - d.x0
                ) {
                    return 0;
                }
                return 1;
            });
        
        // Add icons
        svg.selectAll("icons")
            .data(root.leaves())
            .enter()
            .append("text")
            .attr("x", function (d) {
                return (d.x0 + ((d.x1-d.x0-45)/2));
            })
            .attr("y", function (d) {
                return d.y0 + ((d.y1-d.y0+30)/2);
            }) 
            .text((d: any) => d.data.icons)
            .attr("font-size", `45px`)
            .attr("fill", "var(--consoleWhite)")
            .attr("font-family", "Roboto Mono")
            .style("pointer-events", "none")
            .style("opacity", (d: any) => {
                if (
                    d.data.icons
                ) {
                    return 1;
                }
                return 0;
            });

    }, [width, height]);

    return (
            <div className="treeMap">
                <div className="treeContainer" ref={containerRef}>
                    <svg></svg>
                </div>
            </div>
    );
}
