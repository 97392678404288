import { nanoid } from 'nanoid'
import { Table, Schema } from './Table'
import './Table.css'

interface TableSetProps {
    schemas: Schema[]
}

const placeholderShema: Schema = {
    TableName: "~ Do Select ~",
    Fields: [
        {
            ColumnName: "/////",
            IsNullable: false,
            DataType: "/////",
            MaxLength: 0,
        },
        {
            ColumnName: "\\\\\\\\",
            IsNullable: false,
            DataType: "/////",
            MaxLength: 0,
        },
        {
            ColumnName: "/////",
            IsNullable: false,
            DataType: "\\\\\\\\",
            MaxLength: 0,
        },
        {
            ColumnName: "/////",
            IsNullable: false,
            DataType: "/////",
            MaxLength: 0,
        },
    ],
    Constraints: []
}

export const TableSet = ({schemas}: TableSetProps) => {

    return (
        <div className='tableSet'>
            {schemas.map((s, i) => {
                return <div className={i === 0 ? "first" : "offsetTable"} key={nanoid()}>
                        <Table schema={s}/>
                    </div>
            })}
            {schemas.length === 0 && (
                <div className="first placeholderTable">
                    <Table schema={placeholderShema}/>
                </div>
            )}
        </div>
    )
};
