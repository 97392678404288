import "./HashIcon.css"
import HashIcon from "./HashIcon"
import { useAddDocument } from "../../hooks/useAddDocument"

import { FormEvent, useState } from "react"

export default function HashForm() {
    
    const { doAddDoc } = useAddDocument("/inbound/logging/hashicon")

    const [username, setUsername] = useState<string>("")
    const [formText, setFormText] = useState<string>("")

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setUsername(formText)
        if (formText && formText.length > 0) {
            doAddDoc({iconName: formText, date: new Date()})
        }
    }

    return (
        <div className="hashForm">
            <h2 className="subTitle">Generate your avatar!</h2>
            <form onSubmit={(e) => handleSubmit(e)}>
                <label>
                    <span>NAME / EMAIL</span>
                    <input 
                        type="text"
                        required
                        placeholder='AARON@REBUG.DEV'
                        onChange={(e)=>setFormText(e.target.value)}
                        value={formText}
                    />
                    <button className="darkButton">HASH IT!</button>
                </label>
            </form>
            <div className="mediumGap"></div>
            <div className="flex flex-ai--center iconContainer">
                👋<HashIcon username={username}/>🤙
            </div>
        </div>
    )
}