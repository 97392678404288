import './BannerForm.css'
import { useState } from 'react'
import { useAddDocument } from '../../hooks/useAddDocument'
import { emailFormProps } from './EmailForm'

export default function BannerForm({source}:emailFormProps) {
    const [email, setEmail] = useState('')
    const {doAddDoc, err: error} = useAddDocument("/inbound/contact/mailingList")
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        doAddDoc({email, source})
        setEmail("")
        setSubmitted(true)
    }

    return (
        <div className="articleBanner">
            <div className='bannerContent'>
                {submitted && <h2 className="formTitle subscribed">// SUBSCRIBED //</h2>}
                {!submitted && <h2 className="formTitle">// NEWS LETTER //</h2>}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <input 
                        type="email"
                        required
                        placeholder='MY@EMAIL.COM'
                        onChange={(e)=>{setEmail(e.target.value)}}
                        value={email}
                    />
                    <button className="darkButton" onClick={() => {}}>GO!</button>
                </form>
            </div>
        </div>
    )
}