import "./PostGrid.css"
import { Link } from "react-router-dom"
import { emojiOrder } from "../../util/util"
import { nanoid } from "nanoid"
import { Tooltip } from 'react-tooltip'
import { useNavigate } from "react-router-dom"
import ContentBadge from "../Badge/CategoryBadge"
import { useEffect, useState } from "react"

export default function PostGrid({ articles }) {
    const navigate = useNavigate()
    const handleClick = (docID: string) => {
        navigate(`/post/${docID}`)
    }
    const [emojies, setEmojes] = useState([])

    useEffect(() => {
        setEmojes(prev => articles.map((_, i) => emojiOrder[i % emojiOrder.length]).reverse())
    }, [articles])

    const getEmoji = (index) => {
        return emojies.length === articles.length ? emojies[index] : ""
    }

    return (
        <div className="articles">
            {articles.map((a, i) => {
                return <div className="articleCard" key={nanoid()} onClick={() => handleClick(a.DocID)}>
                    <div className="cardHeader">
                        <Link to={`/post/${a.DocID}`} className="articleTitle">
                            <span className="titleText">
                                <span data-tooltip-id={a.Title.replaceAll(" ", "")} data-tooltip-content={getEmoji(i) + " " + a.Title} className='trimText toolTip'>{getEmoji(i) + " " + a.Title.toUpperCase()}</span>
                            </span>
                        </Link>
                    </div>

                    <div className="cardBody">
                        <div className="cardInfo">
                            <h2 className="articleAuthor">{a.Author}</h2>
                            <span className="separator">{"\\"}</span>
                            <h2 className="articleDate">{a.Date.toISOString().slice(0, 10)}</h2>
                            <div className="badgeContainer"><ContentBadge category={a.Category} /></div>
                        </div>
                        <hr></hr>
                        <div className="articleDescription">"{a.Description || "Click title to find out more!"}"</div>
                        {/* <div className="articleCardFooter">
                            <ContentBadge category={a.Category} />
                        </div> */}
                    </div>
                </div>
            })}
            {Array(9 - articles.length).fill(false).map((i) => {
                return <div className="articleCard skeleton" key={nanoid()}>
                </div>
            })}
            {articles.map(a => <Tooltip id={a.Title.replaceAll(" ", "")} key={nanoid()} />)}
            {articles.length === 0 && <p className="emptyset">{"[ EMPTY SET ]"}</p>}
        </div>
    )
}