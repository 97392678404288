import { createContext, useState, useEffect } from 'react'

export const SchemaContext = createContext()

export const SchemaContextProvider = ({ children }) => {
  const value = ProvideSchemaContext()
  return (
    <SchemaContext.Provider value={value}>
      { children }
    </SchemaContext.Provider>
  )

}

const ProvideSchemaContext = () => {
    const [schemaForm, setSchemaForm] = useState()

    const handleSchemaForm = (tableName, fieldName) => {
      setSchemaForm(prev => {
          return prev.map(s => {
              return s.TableName === tableName ? {...s, Fields: s.Fields.map(f => f.ColumnName === fieldName ? {...f, Selected: !f.Selected} : f)} : s
          })
      })

  }
    return { schemaForm, setSchemaForm, handleSchemaForm  }
}