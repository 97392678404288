import { collection, addDoc} from "firebase/firestore";
import { db } from "../firebase/config";
import { useState } from "react";

export const useAddDocument = (path) => {
    const [err, setErr] = useState(null)

    const doAddDoc = (data) => {
        setErr(null)
        const doAdd = async (data) => {
            data.date = new Date()
            const col = collection(db, path)
            await addDoc(col, data)
        }
        try {
            doAdd(data)
        } catch {
            setErr("Unable to add doc.")
        }
    }

    return { doAddDoc, err }
}