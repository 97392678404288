import { useEffect, useState } from "react"
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";

export const useDocument = (path) => {
    const [document, setDocument] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!path) {
          return
        }
        const unsubscribe = onSnapshot(doc(db, path), (snapshot) => {
          if (snapshot.data()) {
            setDocument({...snapshot.data(), Date: snapshot.data().Date?.toDate()})
          }
        })

        return unsubscribe
    }, [path])
    
    return { document, error }
}