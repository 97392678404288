import "./Modal.css"
import { Dispatch, MouseEvent, useState, SetStateAction } from "react"

interface modalProps {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    title: string
    body: string
    affirmText: string
    onClick: () => void
}

export default function Modal({isOpen, setIsOpen, title, body, affirmText, onClick}:modalProps) {

    return (
        <div className={`modalContainer ${isOpen ? "display" : "displayNone"}`}>
            <div className="modal">
                <div className="closeButton">
                    <button onClick={() => setIsOpen(false)}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </button>
                </div>
                <div className="modalHeader">
                    <h2 className="modalTitle">{title}</h2>
                </div>
                <div className="modalBody">
                    {body}
                </div>
                <div className="modalFooter">
                    <div className="modalButtons">
                        <button className="modalAffirm darkButton" onClick={(e)=>{onClick()}}>{affirmText}</button>
                        <br></br>
                        <button className="modalDeny outlineButton" onClick={() => setIsOpen(false)}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}