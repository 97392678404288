import { SchemaContext } from "../context/SchemaContent"
import { useContext } from "react"

export const useSchemaContext = () => {
  const context = useContext(SchemaContext)

  if(!context) {
    throw Error('useSchemaContext must be used inside a BrowseContextProvider')
  }

  return context
}