import "./Vendors.css"
import Python from '../../assets/python-icon.png'
import Golang from '../../assets/go-icon.png'
import Go from '../../assets/golang-icon.png'
import Node from '../../assets/node-icon.png'
import MySQL from '../../assets/mysql-icon.png'
import Firestore from '../../assets/firebase-icon.png'
import Ferris from '../../assets/ferris-icon1.png'
import Docker from '../../assets/docker-icon.png'
import React from '../../assets/react-icon.png'
import Typescript from '../../assets/typescript-icon.png'
import Postgres from '../../assets/postgres-icon.png'
import Flask from '../../assets/flask-icon.png'
import Rust from '../../assets/rust-icon.png'
// import Linux from '../../assets/linux-icon.png'
import Angular from '../../assets/angular-icon.png'
import Vue from '../../assets/vue-icon.png'
import HTML from '../../assets/html-icon.png'
import CSS from '../../assets/css-icon.png'

export default function Vendors({}) {
    return (
        <div className='vendors'>
            <hr></hr>
            <div className="ofTwo">
                <div className="line1">
                    <div className="topicCard">
                        <img src={Node} alt="Node"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Go} alt="Go" ></img>
                    </div>
                    <div className="topicCard">
                        <img src={React} alt="React"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Firestore} alt="Firestore"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Python} alt="Python"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Docker} alt="Docker"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Ferris} alt="Rust"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Flask} alt="Flask"></img>
                    </div>
                </div>
                <div className="line2">
                    <div className="topicCard">
                        <img src={Typescript} alt="Typescript"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Postgres} alt="Postgres"></img>
                    </div>
                    <div className="topicCard">
                        <img src={MySQL} alt="MySQL"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Angular} alt="Angular"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Vue} alt="Vue"></img>
                    </div>
                    <div className="topicCard">
                        <img src={HTML} alt="HTML" ></img>
                    </div>
                    <div className="topicCard">
                        <img src={CSS} alt="CSS"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Golang} alt="Golang"></img>
                    </div>
                </div>
            </div>

            <div className="ofThree">
            <div className="line1">
                    <div className="topicCard desktopOnly">
                        <img src={Node} alt="Node"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Go} alt="Go"></img>
                    </div>
                    <div className="topicCard">
                        <img src={React} alt="React"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Firestore} alt="Firestore"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Vue} alt="Vue"></img>
                    </div>
                </div>
                <div className="line2">
                    <div className="topicCard">
                        <img src={Python} alt="Python"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Docker} alt="Docker"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Ferris} alt="Rust"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Flask} alt="Flask"></img>
                    </div>
                    <div className="topicCard">
                        <img src={HTML} alt="HTML"></img>
                    </div>
                    <div className="topicCard">
                        <img src={CSS} alt="CSS"></img>
                    </div>
                </div>
                <div className="line3">
                    <div className="topicCard">
                        <img src={Typescript} alt="Typescript"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Postgres} alt="Postgres"></img>
                    </div>
                    <div className="topicCard">
                        <img src={MySQL} alt="MySQL"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Angular} alt="Angular"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Golang} alt="Go"></img>
                    </div>
                </div>
            </div>

            <div className="ofFour">
                <div className="line1">
                    <div className="topicCard desktopOnly">
                        <img src={Node} alt="Node"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Go} alt="Go"></img>
                    </div>
                    <div className="topicCard">
                        <img src={React} alt="React"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Firestore} alt="Firestore"></img>
                    </div>
                </div>
                <div className="line2">
                    <div className="topicCard">
                        <img src={Python} alt="Python"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Docker} alt="Docker"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Ferris} alt="Rust"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Flask} alt="Flask"></img>
                    </div>
                </div>
                <div className="line3">
                    <div className="topicCard">
                        <img src={Typescript} alt="Typescript"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Postgres} alt="Postgres"></img>
                    </div>
                    <div className="topicCard">
                        <img src={MySQL} alt="MySQL"></img>
                    </div>
                </div>
                <div className="line4">
                    <div className="topicCard">
                        <img src={Vue} alt="Vue"></img>
                    </div>
                    <div className="topicCard">
                        <img src={HTML} alt="HTML"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Angular} alt="Angular"></img>
                    </div>
                    <div className="topicCard">
                        <img src={Golang} alt="Go"></img>
                    </div>
                </div>
            </div>
        
            <hr className="btmLine"></hr>
        </div>
    )
}