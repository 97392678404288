import { NavLink, useNavigate } from 'react-router-dom'
import Search from '../Search/Search'
import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
// import { useLogout } from '../../hooks/useLogout'
import "./Nav.css"
import { useBrowseContext } from "../../hooks/useBrowseContext"
import unicorn from "../../assets/rpdsh-thck.svg"
import { useAddDocument } from '../../hooks/useAddDocument'
import { useAuth0 } from "@auth0/auth0-react";

export default function Nav() {
    const [dropOpen, setDropOpen] = useState(false)
    // const { user } = useAuthContext()
    // const { logout } = useLogout()
    const navigate = useNavigate()
    const { setFilter } = useBrowseContext()
    const { doAddDoc } = useAddDocument("/inbound/logging/search")
    const { logout, user, isAuthenticated, isLoading } = useAuth0();

    const handleNav = (to) => {
        setDropOpen(false)
        navigate(to)
    }

    const handleLogin = () => {
        // if (isAuthenticated) {
        //     logout({ logoutParams: { returnTo: window.location.origin } })
        // }
        handleNav("/join")
    }

    const handleSearch = (searchValue) => {
        setFilter(searchValue)
        doAddDoc({searchValue, searchedAt: new Date()})
    }

    return (
        <div className="navContainer">
            {/* <Banner/> */}
            <div className="navBar">
                <div className="container">
                    <div className="navLeft">
                        <div className='brand'>
                            <NavLink to='/'>
                                <span className='logo desktopOnly'><img src={unicorn} alt={"RBG"}></img><span>REBUG</span>.DEV</span>
                            </NavLink>
                            <NavLink to='/'>
                                <span className='logo mobileOnly'><img src={unicorn} alt={"RBG"}></img></span>
                            </NavLink>
                        </div>
                    </div>
                    <Search placeholder={"SEARCH"} options={[]} onSearch={val => handleSearch(val)}/>
                    <div className="navRight">
                        <ul>
                            <li>
                                <NavLink to='/about'>
                                    ABOUT
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/contact'>
                                    CONTACT
                                </NavLink>
                            </li>
                        </ul>
                        <button onClick={() => handleLogin()}>{isAuthenticated ? "INFO" : "JOIN"}</button>
                    </div>
                    <div className='hamburger'>
                        <ul>
                            <li className='hamToggle' onClick={() => setDropOpen(p => !p)}>
                                {dropOpen ? <span className="material-symbols-outlined">close</span> : <span className="material-symbols-outlined">menu</span>}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={`dropNav ${dropOpen ? "open" : "closed"}`}>
                <div className='dropNavButton' onClick={()=>handleLogin()}>
                    <NavLink to='/join'>
                        {isAuthenticated ? "ACCOUNT" : "JOIN"}
                    </NavLink>
                </div>
                <div className='dropNavButton' onClick={()=>handleNav("/about")}>
                    <NavLink to='/about'>
                        ABOUT
                    </NavLink>
                </div>
                <div className='dropNavButton' onClick={()=>handleNav("/contact")}>
                    <NavLink to='/contact'>
                        CONTACT
                    </NavLink>
                </div>
            </div>  
        </div>
    )
}