import { useEffect, useState } from 'react';
import Prism from 'prismjs';
// import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-rust'
import './Theme.css'
import './CodeBox.css'

export default function CodeBox({ code, language, abbreviated }) {
    const [copied, setCopied] = useState(null)
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const handleClick = () => {
        navigator.clipboard.writeText(code)
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
    }

    return (
        <div className={abbreviated ? "codeBox abbreviated" : "codeBox"}>
            <div className={!copied ? 'copyBtn rotate' : 'copyBtn checkBtn'}>
                {!copied && (
                    <span className="material-symbols-outlined" onClick={(handleClick)}>
                        content_copy
                    </span>
                )}
                {copied && (
                    <span className="material-symbols-outlined">
                        check
                    </span>
                )}
            </div>
            <pre><code className={`language-${language?.toLowerCase() || "go"}`}>{code}</code></pre>
        </div>
    )
}