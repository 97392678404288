import "./About.css"

export default function About() {
    return (
    <div className="about infoCard">
        <div className="container aboutContainer">
            <div className="aboutText">
            <h2 className="subTitle">👋 About</h2>
                <div className="aboutExplanation">
                    REBUG.DEV is a software/development focused knowledge base. We host articles that aim to edify and inspire. 
                    We believe that exploration is an iterative process and success is awarded to those who dare beyond the beaten path. 
                    You must fail to succeed… that is to say, you must rebug to debug.
                </div>
                <div className="aboutSignature">
                    "Hello! I'm Aaron, the creator/maintainer of REBUG.DEV. Thanks for stopping by!"
                </div>
            </div>
        </div>
    </div>
    )
}