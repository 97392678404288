import "./Join.css"
import EmailForm from "../../components/EmailForm/EmailForm"
import InfoCard from "../../components/Info/Info"
import {Helmet} from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react";

export default function Join() {
    
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

    return (
        <div className="page blastPage article join">

            <Helmet>‍
                <title>Join - REBUG</title>‍
                <meta name="description" content="Create an account and join the REBUG.DEV community."/>        
            </Helmet>

            <div className="smallGap"></div>
            {isAuthenticated && (
                <div className="loginButtons container">
                    <div className="buttons">
                        <span className="articleText">Signed in as: <span className="email">{user.email.split("@")[0]}</span></span>
                        <button className="signupBtn darkButton" onClick={() => logout()}>Logout</button>
                    </div>
                </div>
            )}
            {!isAuthenticated && (
                <div className="loginButtons container">
                    <div className="buttons">
                        <span className="articleText">Not signed in.</span>
                        <button className="signupBtn darkButton" onClick={() => loginWithRedirect()}>Sign Up</button>
                        <button className="loginBtn" onClick={() => loginWithRedirect()}>Log in</button>
                    </div>
                </div>
            )}
            <div className="container">
                <h1 className="bigTitle">News Letter!</h1>
                <EmailForm source="/join"/>
                <div className="mediumGap"></div>
                <div className="smallGap"></div>
                <div className="infoContainer">
                    <InfoCard
                    title={"Why tho?"}
                    body={"If you drop me your email, I'll build an automated mailing list service, write an article about it, then use the service to email you about the article. Find out!"}
                    />
                </div>
            </div>
            <div className="mediumGap"></div>
        </div>
    )
}