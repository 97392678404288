import "./Author.css"
import { useBrowseContext } from "../../hooks/useBrowseContext"
import AuthorNav from "../../components/PostList/AuthorNavigation"
import PostGridSkeleton from "../../components/PostList/PostGridSkeleton"
import {Helmet} from "react-helmet"
import PostGrid from "../../components/PostList/PostGrid"
export default function Author() {
    const { documents, filter, loading } = useBrowseContext()

    return (
        <div className="page">  
            <Helmet>‍
                <title>Author - REBUG</title>‍
                <meta name="description" content="Browse and search an author's published content."/>        
            </Helmet>
            
            <div className="container">
                <div className="index author">
                    {loading && <PostGridSkeleton/>}
                    {!loading && (
                        <>
                            <AuthorNav/>
                            <PostGrid articles={documents}/>
                        </>
                    )}
                    <div className="mediumGap"></div>
                    <div className="mediumGap"></div>
                </div>
            </div>
        </div>
    )
}