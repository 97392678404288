import { SchemaContextProvider } from "../../context/SchemaContent";
import { NetworkDiagram } from "./NetworkDiagram";
// TODO drag viewport/pan
export const NetworkWrapper = () => {
    return (
        <SchemaContextProvider>
            <NetworkDiagram/>
        </SchemaContextProvider>
    );
};
