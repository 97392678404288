import "./Comment.css"
import { comment, Comment } from "./Comment"
import { nanoid } from "nanoid"

interface commentListProps {
    comments: comment[]
    reply: boolean
}

export default function CommentList({comments, reply}:commentListProps) {
    return (
        <div className="commentList">
            <ul>
                {comments.map((comment) => {
                    return <li key={nanoid()}>
                        <Comment comment={comment} reply={reply}/>
                    </li>
                })}
            </ul>
        </div>
    )
}