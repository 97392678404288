import { useState } from 'react';
import { auth } from '../firebase/config';
import { useAuthContext } from './useAuthContext';
import { signInWithEmailAndPassword } from "firebase/auth";

const backendErrors = [
  'There is no user record corresponding to this identifier. The user may have been deleted.',
  'The password is invalid or the user does not have a password.'
]

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const login = async (email, password) => {
    setError(null)
    setIsPending(true)
  
    try {
      // login
      const res = await signInWithEmailAndPassword(auth, email, password)

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(backendErrors.includes(err.message) ? 'Username or password incorrect...' : err.message )
        setIsPending(false)
      }
    }
  }

  const clearError = () => {
    setError(null)
  }

  return { login, isPending, error, clearError }
}