import { useEffect } from "react"
import { doc, setDoc} from "firebase/firestore";
import { db } from "../firebase/config";

export const useWriteDocument = async (path, data) => {

    useEffect(() => {
        if (!path) {
            return
        }
        const doMake = async () => {
            await setDoc(doc(db, path), data)
        }

        doMake()

    }, [path])

    return { document }
}