import "./PostGrid.css"
import { useBrowseContext } from "../../hooks/useBrowseContext"
export default function GridNav({title}) {
    const {page, totalPages, nextPage, prevPage, filter} = useBrowseContext()

    const handleClick = (dir:string) => {
        if (dir === "next" && page < (totalPages - 1)) {
            nextPage()
        } else if (dir === "prev" && page > 0) {
            prevPage()
        }
    }
    
    return (
        <div className="gridNav">
            <h2 className="subTitle">
                <span className="material-symbols-outlined direct">
                    reply
                </span>
                {title || "LATEST POSTS"}
                <div className="navArrows">
                    <button className={(page === 0 || filter.length > 0) ? "disabled" : ""} onClick={()=>handleClick("prev")}>
                        <span className="material-symbols-outlined previous">
                            double_arrow
                        </span>
                    </button>
                    <button className="disabled navInfo">
                        <span>
                            {filter.length > 0 ? "1/1" : `${page + 1}`+`/${totalPages}`}
                        </span>
                    </button>
                    <button className={(page === totalPages - 1 || filter.length > 0) ? "disabled" : ""} onClick={()=>handleClick("next")}>
                        <span className="material-symbols-outlined next">
                            double_arrow
                        </span>
                    </button>
                </div>
            </h2>
        </div>
    )
}