import "./ArticleVideo.css"

export default function ArticleVideo({src, type}) {
    return (
        <div className="article-video">
            <video autoPlay muted loop>
                <source src={src} type={type || "video/mp4"}></source>
                Your browser does not support the video tag.
            </video>
        </div>
    )
}