import "./Login.css"
import AuthForm from "../../components/AuthForm/AuthForm"
import { useState } from "react"
import {Helmet} from "react-helmet"

export default function Login() {
    const [isLogin, setIsLogin] = useState(false)

    return (
        <div className="page blastPage article">
            <Helmet>‍
                <title>Login - REBUG</title>‍
                <meta name="description" content="Log into your REBUG.DEV account."/>        
            </Helmet>
            <div className="smallGap"></div>
            <div className="container">
                <h1 className="bigTitle">{isLogin ? "Welcome back!" : "New account!"}</h1>
                <AuthForm isLogin={isLogin}/>
                <p className="footLink" onClick={()=>setIsLogin(prev=>!prev)}>{isLogin ? "CREATE A NEW ACCOUNT?" : "ALREADY HAVE AN ACCOUNT?"}</p>
            </div>
            <div className="mediumGap"></div>
        </div>
    )
}