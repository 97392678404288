import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { useState } from "react";

export const useContact = () => {
    const [err, setErr] = useState(null)
    const postMessage = (message) => {
        setErr(null)
        const push = async (message) => {
            message.date = new Date()
            const col = collection(db, "/inbound/contact/webForm")
            await addDoc(col, message)
        }
        try {
            push(message)
        } catch {
            setErr("Unable to post message.")
        }
    }
  
  return { postMessage, err }
}