import GitTreeMapWrapper from "../../components/TreeMap/GitTreeMapWrapper"

export default function GitMap() {

    return (
        <div className="page darkPage">
            <div className="smallGap"></div>
            <div className="container">
                <GitTreeMapWrapper/>
            </div>
        </div>
    )
}