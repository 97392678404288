import "./PagerFeed.css"
import TalkingHead from "../TalkingHead/TalkingHead"
import { useEffect, useState } from "react"
import { nanoid } from "nanoid"
import { useWriteDocument } from "../../hooks/useWriteDocument"
import { useDocument } from "../../hooks/useDocument"
import { randSmileys, colors } from "../../util/util"

export default function PagerFeed() {
    const [sessionID, setSessionID] = useState(nanoid(6))
    const [headPairs, setHeadPairs] = useState([])
    const {document } = useDocument(`inbound/pager/sessions/${sessionID}`)

    useWriteDocument(`inbound/pager/sessions/${sessionID}`, {
        elements: [],
        createdAt: new Date(),
        fromNumber: "",
    })

    useEffect(() => {
        document?.elements && document.elements.forEach((elem, index) => {
            if (index > headPairs.length - 1) {
                setHeadPairs(prev => [...prev, [colors.random(), randSmileys.random()]])
            }
        })
    }, [document])

    return (
        <div className="PagerFeed">
            {(document?.fromNumber === "") && (
                <div className="emptyMessage">📞 Waiting for signal on session id: <strong><code>{sessionID}</code></strong></div>
            ) }
            {(document?.fromNumber !== "" && document?.elements && document.elements.length === 0) && (
                <div className="emptyMessage">✨🔗 Session has been linked!</div>
            ) }
            {document && document.elements?.map((e, i) => {
                return <div key={nanoid()} className={i === document.elements.length - 1 ? "lastHead": "stackedHead"}>
                            {headPairs.length > i && <TalkingHead quote={e.Content} color={headPairs[i][0]} faceIndex={headPairs[i][1]}/>}
                        </div>
            })}
        </div>
    )
}