import "./Info.css"

export default function InfoCard({title, body}) {
    return (
    <div className="infoCard">
        <h2 className="infoTitle">
            <span className="material-symbols-outlined">
                info
            </span>
            {title}
        </h2>
        <div className="infoBody">
            <p>{body}</p>
        </div>

    </div>
    )
}