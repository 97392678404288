import { useState } from "react"
import "./Search.css"
import { nanoid } from "nanoid"
import { useNavigate } from "react-router-dom"
import { analytics } from "../../firebase/config"
import { logEvent } from "firebase/analytics"

export default function Search({placeholder, onSearch, options}) {
    const [searchFor, setSearchFor] = useState("")
    const [suggestions, setSuggestions] = useState([])
    const navigate = useNavigate()
    
    const handleSubmit = (e) => {
        e && e.preventDefault()
        e && e.target[0].blur()
        logEvent(analytics, "search", {"query": searchFor})
        onSearch(searchFor)
        navigate("/articles")
    }
    const haneleUpdate = (e) => {
        setSearchFor(e.target.value)
        setSuggestions(options.map((opt)=>opt.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    return (
        <div className='search'>
            <div className="searchbar">
                <span className="material-symbols-outlined icon">
                    search
                </span>
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <input type="text" placeholder={placeholder} onChange={haneleUpdate}></input>
                </form>
            </div>
            <div className="suggestions">
                <ul>{suggestions.map((item)=><li key={nanoid()}>{item}</li>)}</ul>
            </div>
        </div>
    )
}