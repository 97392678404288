import { nanoid } from 'nanoid'
import './Table.css'
import { useState } from 'react'
import { useSchemaContext } from '../../hooks/useSchemaContext'

export interface Constraint {
    ColumnName: string
    ConstraintName: string
    ReferencedTableName: string
    ReferencedColumnName: string
}
interface Field {
    ColumnName: string
    IsNullable: boolean
    DataType: string
    MaxLength: null | number
    Selected?: boolean
}
export interface Schema {
    TableName: string
    Fields : Field[]
    Constraints: Constraint[]
}
export interface TableProps {
    schema: Schema
}
export const Table = ({schema}: TableProps) => {
    const { handleSchemaForm, schemaForm } = useSchemaContext()

    const handleClick = (name: string) => {
        if (schema.TableName != "~ Do Select ~") {
            handleSchemaForm(schema.TableName, name)
        }
    }

    return (
        <div className="table">
            <div className='tableTitle'>
                <h2 className="tableName">{schema.TableName}</h2>
            </div>
            <div className='tableBody'>
                <div className='tableFields tableHeader'>
                    <div className="columnTitle">Column</div>
                    <div className="columnTitle">Type</div>
                    <div className="columnTitle">Null</div>
                    <div className="columnTitle">Max</div>
                </div>
                {schema.Fields.map((f) => {
                    return <div key={nanoid()} className={`tableFields ${schemaForm?.find(s => s.TableName === schema.TableName)?.Fields?.find(fi => fi.ColumnName === f.ColumnName)?.Selected ? "activeField" : ""}`} onClick={() => handleClick(f.ColumnName)}>
                        <div className="columnName">{f.ColumnName}</div>
                        <div className="dataType">{f.DataType}</div>
                        <div className="isNullable">false</div>
                        <div className="isMaxLength">{f.MaxLength}</div>
                    </div>
                })}
            </div>
        </div>
    )
};
