import "./Index.css"
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useBrowseContext } from "../../hooks/useBrowseContext"
import PostGrid from "../../components/PostList/PostGrid"
import PostGridSkeleton from "../../components/PostList/PostGridSkeleton"
import GridNav from "../../components/PostList/GridNavigation"
import {Helmet} from "react-helmet"

export default function Index() {
    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const { documents, filter, loading } = useBrowseContext()

    return (
        <div className="page">
            <Helmet>‍
                <title>Content - REBUG</title>‍
                <meta name="description" content="Browse and search for published content."/>        
            </Helmet>
            
            <div className="container">
                <div className="smallGap"></div>
                <div className="index">
                    <h1 className="articleTitle bigTitle">{"Content"}</h1>
                    {loading && <PostGridSkeleton/>}
                    {!loading && (
                        <>
                            <GridNav title={filter ? "SEARCH RESULTS" : "LATEST POSTS"}/>
                            <PostGrid articles={documents}/>
                        </>
                    )}
                    <div className="mediumGap"></div>
                    <div className="mediumGap"></div>
                </div>
            </div>
        </div>
    )
}