import Badge from "./Badge"

// How To
// What If 
// Showcase

interface categoryProps {
    category: string
}
export default function ContentBadge({category}:categoryProps) {
    let color = "orange";
    if (category.toLowerCase() === "showcase" ) {
        color = "purple"
    } else if (category.toLowerCase() === "how to" ) {
        color = "green"
    } else if (category.toLowerCase() === "misc" ) {
        color = "yellow"
    }
    return (
        <Badge size="medium" color={color} text={category}/>
    )
}