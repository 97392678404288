import "./App.css";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import Nav from "./components/Layout/Nav";
import Index from "./routes/Index/Index";
import AboutRoute from "./routes/About/About";
import PostPage from "./routes/Post/Post";
import Contact from "./routes/Contact/Contact";
import Login from "./routes/Login/Login";
import Landing from "./routes/Landing/Landing";
import NotFound from "./routes/NotFound/NotFound";
import Footer from "./components/Layout/Footer";
import ScrollToTop from "./hooks/scroll";
import Staging from "./routes/Staging/Staging";
import Join from "./routes/Join/Join";
import GitMap from "./routes/GitMaps/GitMap";
import { useAuth0 } from "@auth0/auth0-react";
import Privacy from "./routes/PrivacyTerms/Privacy";
import Terms from "./routes/PrivacyTerms/Terms";
import Author from "./routes/Author/Author";
// import { useAuthContext } from "./hooks/useAuthContext";

function App() {
    // const { user, authIsReady } = useAuthContext();
    const { user, isAuthenticated, isLoading } = useAuth0();
    return (
        <div className="App">
            {true && (                
                <BrowserRouter>
                    <ScrollToTop/>
                    <Nav/>
                    <Routes>
                        <Route path="/" exact element={<Landing/>}/>
                        <Route path="/articles" exact element={<Author/>}/>
                        {/* <Route path="/articles" exact element={<Index/>}/> */}
                        <Route path="/about" exact element={<AboutRoute/>} />
                        <Route path="/post/:id" exact element={<PostPage/>} />
                        <Route path="/contact" exact element={<Contact/>} />
                        <Route
                            path="/login"
                            exact
                            element={isAuthenticated ? <Landing/> : <Login/>}
                        />
                        <Route path="/join" exact element={<Join/>} />
                        <Route
                            path="/sign-up"
                            exact
                            element={isAuthenticated ? <Index/> : <Login/>}
                        />
                        <Route path="/terms" exact element={<Terms/>} />
                        <Route path="/privacy" exact element={<Privacy/>} />
                        <Route path="/staging" exact element={<Staging/>} />
                        <Route path="/gitmap" exact element={<GitMap/>} />
                        <Route path="*" exact element={<NotFound />} />
                    </Routes>
                    <Footer />
                </BrowserRouter>
            )}
        </div>
    );
}

export default App;
