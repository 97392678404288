import "./CounterBadge.css"

interface counterBadgeProps {
    icon: string
    count: number
    color?: string
    size?: string
    style?: string
}
export default function CounterBadge({icon, count, color, size, style}:counterBadgeProps) {
    return (
        <div className={`counterBadge ${color || "green"} ${size || "medium"} ${style || "bubble"}`}>
            <span className="material-symbols-outlined icon">
                {icon}
            </span>
            <div className="count"><span>{count}</span></div>
        </div>
    )
}