import { Table, Schema } from './Table'

export const data = {
    nodes: [
        { id: '1', name: 'Jorble' },
        { id: '2', name: 'Worble' },
        { id: '3', name: 'Snazl' },
        { id: '4', name: 'Kringle' },
        { id: '5', name: 'Jangle' },
        { id: '6', name: 'Skornj' },
        { id: '7', name: 'Mangr' },
        { id: '8', name: 'Taningbm' },
        { id: '9', name: 'Swieze' },
        { id: '10', name: 'LorPj' },
        { id: '11', name: 'Fonningham' },
        { id: '12', name: 'Crembrl' },
        { id: '13', name: 'Torntn' },
        { id: '14', name: 'Skizmd' },
        { id: '15', name: 'Rendrr' },
        { id: '16', name: 'Mangr' },
        { id: '17', name: 'Worble' },
    ],
    links: [
        { source: '2', target: '1', value: 1 },
        { source: '11', target: '1', value: 1 },
        { source: '3', target: '1', value: 1 },
        { source: '4', target: '1', value: 1 },
        { source: '5', target: '6', value: 1 },
        { source: '6', target: '7', value: 1 },
        { source: '8', target: '1', value: 1 },
        { source: '8', target: '4', value: 1 },
        { source: '8', target: '1', value: 1 },
        { source: '4', target: '1', value: 1 },
        { source: '10', target: '9', value: 1 },
        { source: '15', target: '13', value: 1 },
        { source: '15', target: '14', value: 1 },
        { source: '15', target: '12', value: 1 },
        { source: '15', target: '17', value: 1 },
        { source: '17', target: '12', value: 1 },
        { source: '17', target: '14', value: 1 },
        { source: '17', target: '16', value: 1 },
        { source: '10', target: '16', value: 1 },
    ],
};

export const tableSchemas: Schema[] = [
    {
        TableName: "user",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "name",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
            {
                ColumnName: "lastname",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
            {
                ColumnName: "email",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },

        ],
        Constraints: []
    },
    {
        TableName: "post",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "author",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "content",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
            {
                ColumnName: "title",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
        ],
        Constraints: [
            {
                ColumnName: "author", 
                ConstraintName: "user_constraint",
                ReferencedTableName: "user", 
                ReferencedColumnName: "id"
            }
        ]
    },
    {
        TableName: "comment",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "author",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "post",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "content",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
        ],
        Constraints: [
            {
                ColumnName: "author", 
                ConstraintName: "user_constraint",
                ReferencedTableName: "user", 
                ReferencedColumnName: "id"
            },
            {
                ColumnName: "post", 
                ConstraintName: "post_constraint",
                ReferencedTableName: "post", 
                ReferencedColumnName: "id"
            }
        ]

    },
    {
        TableName: "notification",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "user_id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "like_id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "date",
                IsNullable: false,
                DataType: "timestamp",
                MaxLength: 255,
            },
        ],
        Constraints: [
            {
                ColumnName: "like_id", 
                ConstraintName: "like_constraint",
                ReferencedTableName: "like", 
                ReferencedColumnName: "id"
            }
        ]
    },
    {
        TableName: "like",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "comment",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "date",
                IsNullable: false,
                DataType: "timestamp",
                MaxLength: 255,
            },
            {
                ColumnName: "hidden",
                IsNullable: true,
                DataType: "boolean",
                MaxLength: 1,
            },
        ],
        Constraints: [
            {
                ColumnName: "comment", 
                ConstraintName: "comment_constraint",
                ReferencedTableName: "comment", 
                ReferencedColumnName: "id"
            }
        ]
    },


    {
        TableName: "project",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "owner_id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "team_id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "name",
                IsNullable: false,
                DataType: "string",
                MaxLength: 128,
            },
        ],
        Constraints: [
            {
                ColumnName: "owner_id", 
                ConstraintName: "owner_constraint",
                ReferencedTableName: "owner", 
                ReferencedColumnName: "id"
            },
            {
                ColumnName: "team_id", 
                ConstraintName: "team_constraint",
                ReferencedTableName: "team", 
                ReferencedColumnName: "id"
            }
        ]
    },
    {
        TableName: "owner",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "name",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
            {
                ColumnName: "lastname",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
            {
                ColumnName: "email",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
        ],
        Constraints: []
    },
    {
        TableName: "team_member",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "member",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "team_id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "active",
                IsNullable: true,
                DataType: "boolean",
                MaxLength: 1,
            },
        ],
        Constraints: [
            {
                ColumnName: "member", 
                ConstraintName: "member_constraint",
                ReferencedTableName: "member", 
                ReferencedColumnName: "id"
            },
            {
                ColumnName: "team_id", 
                ConstraintName: "team_constraint",
                ReferencedTableName: "team", 
                ReferencedColumnName: "id"
            }
        ]
    },
    {
        TableName: "team",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "name",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
            {
                ColumnName: "desc",
                IsNullable: false,
                DataType: "text",
                MaxLength: null,
            },
            {
                ColumnName: "score",
                IsNullable: false,
                DataType: "float",
                MaxLength: null,
            },
        ],
        Constraints: []
    },
    {
        TableName: "member",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "team_id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "name",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
            {
                ColumnName: "lastname",
                IsNullable: false,
                DataType: "string",
                MaxLength: 255,
            },
        ],
        Constraints: [
            {
                ColumnName: "team_id", 
                ConstraintName: "team_constraint",
                ReferencedTableName: "team", 
                ReferencedColumnName: "id"
            }
        ]
    },
    {
        TableName: "project_user",
        Fields: [
            {
                ColumnName: "id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "project",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "user_id",
                IsNullable: false,
                DataType: "number",
                MaxLength: 128,
            },
            {
                ColumnName: "active",
                IsNullable: true,
                DataType: "boolean",
                MaxLength: 1,
            },
        ],
        Constraints: [
            {
                ColumnName: "project", 
                ConstraintName: "project_constraint",
                ReferencedTableName: "project", 
                ReferencedColumnName: "id"
            },
            {
                ColumnName: "member_id", 
                ConstraintName: "member_constraint",
                ReferencedTableName: "member", 
                ReferencedColumnName: "id"
            }
        ]
    },
]