import { createContext, useState, useEffect } from 'react'
import { useCollection } from '../hooks/useCollection'
import { useDocument } from '../hooks/useDocument'
import { db } from "../firebase/config";
import { collection, query, where, onSnapshot, orderBy, limit, startAfter, getDocs} from "firebase/firestore";


export const BrowseContext = createContext()

export const BrowseContextProvider = ({ children }) => {
  const value = ProvideBrowseContext({pageSize: 9})
  return (
    <BrowseContext.Provider value={value}>
      { children }
    </BrowseContext.Provider>
  )
}

const ProvideBrowseContext = ({pageSize}) => {
    // list nav
    const [_startAfter, _setStartAfter] = useState(null)
    const [startIndices, setStartIndices] = useState([])
    const [filter, setFilter] = useState("")
    const { documents, count, loading } = useCollection("public/articles/Aaron", filter.length > 0 ? ["Search", "array-contains", filter.toLowerCase()] : null, ["Date","desc"], pageSize || 10, _startAfter)
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [latestArticleID, setLatestArticleID] = useState(null)

    // article nav
    const [activeArticleID, setActiveArticleID] = useState(null)
    const [activePrevID, setActivePrevID] = useState(null)
    const [activeNextID, setActiveNextID] = useState(null)
    const {document: activeArticle} = useDocument(activeArticleID ? `public/articles/Aaron/${activeArticleID}` : null)

    useEffect(() => {
      setPage(startIndices.length)
      setTotalPages(Math.ceil(count/(pageSize || 10)))
    }, [count, startIndices])

    useEffect(() => {
      // set once on first load
      if (!latestArticleID && documents?.length > 0) {
        setLatestArticleID(documents[0].DocID)
      }
    },[documents])

    useEffect(() => {
      if (!activeArticle) {
        return
      }
      const doFetch = async () => {
        const nextSnap = await getDocs(query(collection(db, "public/articles/Aaron"), orderBy("Date","desc"), limit(1), startAfter(activeArticle.Date)))
        const prevSnap = await getDocs(query(collection(db, "public/articles/Aaron"), orderBy("Date","asc"), limit(1), startAfter(activeArticle.Date)))
        if (nextSnap.size > 0){
          setActiveNextID(nextSnap.docs[0].id)
        } else {
          setActiveNextID(null)
        }
        if (prevSnap.size > 0){
          setActivePrevID(prevSnap.docs[0].id)
        } else {
          setActivePrevID(null)
        }
      }
      doFetch()
    }, [activeArticle])


    const nextPage = () => {
      if (documents.length === 0){
        return
      }
      _setStartAfter(documents[documents.length -1].Date)
      setStartIndices(prev => [...prev, documents[documents.length -1].Date])
    }
    
    const prevPage = () => {
      if (documents.length === 0 || startIndices.length === 0){
        return
      }
      setStartIndices(prev => prev.slice(0, prev.length - 1))
      _setStartAfter(setStartIndices[setStartIndices.length-1])
    }

    const reset = () => {
      if (setStartIndices.length > 0) {
        setStartIndices([])
        _setStartAfter(null)
      }
    }

    return { 
      loading, 
      documents, 
      prevPage, 
      nextPage, 
      filter, 
      setFilter, 
      page, 
      totalPages, 
      setActiveArticleID, 
      activeArticle, 
      activeArticleID, 
      activePrevID, 
      activeNextID, 
      latestArticleID,
      reset
    }
}