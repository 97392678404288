import "./Landing.css"
import { useBrowseContext } from "../../hooks/useBrowseContext"
import About from "../../components/Layout/About"
import PostGrid from "../../components/PostList/PostGrid"
import { useNavigate } from "react-router-dom"
import { useRef, useEffect } from "react"
import PostGridSkeleton from "../../components/PostList/PostGridSkeleton"
import Vendors from "../../components/Vendors/Vendors"
import {Helmet} from "react-helmet"
import LandingHero from "./LandingHero"

export default function Landing() {
    const { documents, setFilter, latestArticleID, loading, reset } = useBrowseContext()
    const navigate = useNavigate()
    const containerRef = useRef(null);

    const onButtonClick = (to) => {
        navigate(to)
    }

    useEffect(()=>{
        setFilter("")
        reset()
    },[])

    return (
        <div className="background">
            <Helmet>‍
                <title>Home - REBUG</title>‍
                <meta name="description" content="Browse general information about REBUG.DEV and explore new publications."/>        
            </Helmet>

            <LandingHero latestArticleID={latestArticleID} handleClick={onButtonClick}/>
            <div className={"landingPage"} ref={containerRef}>

                <div className="container landingContainer">

                    {/* ARTICLES */}
                    {loading && <PostGridSkeleton/>}
                    {!loading && (
                        <PostGrid articles={documents}/>
                    )}

                    {/* VENDORS */}
                    <div className="vendorSection">
                        <h2 className="subTitle vendorTitle">TOPICS</h2>
                        <Vendors/>
                    </div>
                </div>
                {/* ABOUT / CONTACT */}
                <About/>
            </div>
        </div>
    )
}