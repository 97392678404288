import './ContactForm.css'
import { useState } from 'react'
import { useContact } from '../../hooks/useContact'
import ErrorText from '../Layout/ErrorText'

export default function ContactForm() {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState(null)
    const {postMessage, err: error} = useContact()

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        postMessage({name, email, subject, message})
        setLoading(false)
        clearForm()
        setFeedback("Thanks for the message, we'll review it soon 🙂!")
    }

    const clearForm = () => {
        setEmail('')
        setName('')
        setSubject('')
        setMessage('')
    }

    return (
        <div className='contactForm'>
            <form onSubmit={handleSubmit}>
                <label>
                    <span>NAME</span>
                    <input 
                        type="text"
                        placeholder='JORKLETON'
                        required
                        onChange={(e)=>setName(e.target.value)}
                        value={name}
                    />
                </label>
                <label>
                    <span>EMAIL</span>
                    <input 
                        type="email"
                        placeholder='MY@EMAIL.COM'
                        required
                        onChange={(e)=>setEmail(e.target.value)}
                        value={email}
                    />
                </label>
                <label>
                    <span>SUBJECT</span>
                    <input 
                        type="text"
                        placeholder='FEEDBACK'
                        required
                        onChange={(e)=>setSubject(e.target.value)}
                        value={subject}
                        maxLength={80}
                    />
                </label>
                <label>
                    <span>MESSAGE</span>
                    <textarea
                        type="text-area"
                        placeholder='I THINK...'
                        onChange={(e)=>setMessage(e.target.value)}
                        value={message}
                        maxLength={600}
                    />
                </label>
                <div className='buttons'>
                    {loading ?  <button disabled className="btn mainButton">LOADING</button> : <button className="btn mainButton">SUBMIT</button>}
                    <button className='btn secondButton' onClick={clearForm}>CLEAR</button>
                </div>
                {error && <><div className='smallGap'></div><ErrorText text={error}/></>}
                {(!error && feedback) && <div><div className='smallGap'></div><div className='feedback'>{feedback}</div></div>}
            </form>
        </div>
    )
}