import "./Contact.css"
import ContactForm from "../../components/ContactForm/ContactForm"
import {Helmet} from "react-helmet"

export default function Contact() {
    return (
        <div className="page blastPage article">

            <Helmet>‍
                <title>Contact - REBUG</title>‍
                <meta name="description" content="Contact REBUG.DEV team with thoughts or feedback."/>        
            </Helmet>

            <div className="smallGap"></div>
            <div className="container">
                <h1 className="bigTitle">Contact</h1>
                <ContactForm/>
                <p>{"[ "}DIRECT EMAIL: <strong>AARON@REBUG.DEV</strong>{" ]"}</p>
            </div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
        </div>
    )
}