import { useEffect, useState } from 'react'
import { DoMultiTraversal, Link, Node, GNode, NewGraph, AllPaths } from '../util/GraphNode'

const colorScheme = [
    "rgb(90, 167, 255)",
    "rgb(71, 103, 245)",
]

const uncolorScheme = [
    "rgba(90, 167, 255,0.3)",
    "rgba(90, 167, 255,0.3)",
]

const strokeColor = "rgb(71, 103, 245)"
const unstrokeColor = "rgba(71, 103, 245,0.3)"
const color = (index: number, fade: boolean): string => {
    return fade ? uncolorScheme[index % colorScheme.length] : colorScheme[index % colorScheme.length]
}


interface useNetParams {
    nodes: Node[]
    links: Link[]
    canvas: HTMLCanvasElement
    width: number
    height: number
}

export const useNetGraph = ({nodes, links, canvas, width, height}: useNetParams) => { 

    const RADIUS = 15;
    const [activeNodes, setActiveNodes] = useState(new Set<string>())
    const [activeLinks, setActiveLinks] = useState(new Set<string>())
    const [activePaths, setActivePaths] = useState([])
    const [ActiveGraph, SetActiveGraph] = useState(new Map<string, GNode>())
    const [context, setContext] = useState(canvas?.getContext('2d'))
    const [nodeIDName, setNodeIDName] = useState({})

    useEffect(() => {
        if (!nodes) {
            return
        }
        SetActiveGraph(NewGraph(nodes, links))
        const idName = {}
        nodes.forEach(n => idName[n.id] = n.name)
        setNodeIDName(idName)
    }, [nodes, links])

    useEffect(() => {
        setContext(canvas?.getContext('2d'))
    }, [canvas])

    useEffect(() => {
        const activeNodeList: string[] = []
        activeNodes.forEach(n => activeNodeList.push(n))
        if (activeNodes.size > 0) {
            const paths = AllPaths(ActiveGraph.get(activeNodeList[0]), activeNodeList[activeNodeList.length - 1])
            const candidateLinks = new Set<string>()
            for (let i = 0; i < links.length; i ++) {
                const l = links[i]
                let found = false
                for (let j = 0; j < paths.length; j++) {
                    const p = paths[j]
                    const s = typeof l.source === "string" ? l.source : l.source.id
                    const t = typeof l.target === "string" ? l.target : l.target.id
                    for (let ii = 1; ii< p.length; ii++) {
                        if ((p[ii-1] === s && p[ii] === t) || (p[ii-1] === t && p[ii] === s)) {
                            candidateLinks.add(s + "-" + t)
                            candidateLinks.add(t + "-" + s)
                            found = true
                            break
                        }
                    }
                    if (found) {
                        break
                    }
                }
            }
            setActiveLinks(new Set<string>(candidateLinks))
            for (let i = 0; i < paths.length; i ++) {
                for (let j = 0; j < paths[i].length; j++) {
                    paths[i][j] = nodeIDName[paths[i][j]]
                }
            }
            setActivePaths(paths)
        } else {
            setActiveLinks(new Set<string>())
            setActivePaths([])
        }
    }, [activeNodes, ActiveGraph])

    useEffect(() => {
        DrawNetwork(context);
    }, [nodes, links, activeNodes, activeLinks])

    const linkIsActive = (link: Link):boolean => {
        return activeLinks.has(link.source.id + "-" + link.target.id) || activeLinks.has(link.target.id + "-" + link.source.id)
    }

    const DrawNetwork = (context) => {
        if (!context){
            return
        }
        context.clearRect(0, 0, width, height);
        context.font = "12px Geologica";
        // Draw the links first
        links?.forEach((link) => {
            context.beginPath();
            context.moveTo(link.source.x, link.source.y);
            context.lineTo(link.target.x, link.target.y);
            const isActive = linkIsActive(link)
            if (isActive) {
                context.lineWidth = 3;
            } else {
                context.lineWidth = 2;
            }
            if (activeNodes.size === 0 || linkIsActive(link)) {
                context.strokeStyle = strokeColor;
            } else {
                context.strokeStyle = unstrokeColor;
            }
            context.stroke();
        });
    
        // Draw the nodes
        nodes?.forEach((node) => {
            if (!node.x || !node.y) {
                return;
            }
    
            context.beginPath();
            context.moveTo(node.x + RADIUS, node.y);
            context.arc(node.x, node.y, RADIUS, 0, 2 * Math.PI);
            context.fillStyle = (activeNodes.size === 0 || activeNodes.has(node.id)) ? color(node.index, false) : color(node.index, true);
            context.fill();
            context.fillText(node.name, node.x+15, node.y-15)
        });
    };
    
    const HandleClick = (e: React.MouseEvent) => {
        var r = canvas.getBoundingClientRect(), x = e.clientX - r.left, y = e.clientY - r.top;
    
        let clickedNode: Node | null = null;
    
        for (let i = 0; i < nodes.length; i++) {
            if (!nodes[i].x || !nodes[i].y) {
                continue;
            }
            if ((x > nodes[i].x - RADIUS) && (y > nodes[i].y - RADIUS) && (x < nodes[i].x + RADIUS) && (y < nodes[i].y + RADIUS)) {
                clickedNode = nodes[i];
                break;
            }  
        }
        if (clickedNode === null) {
            setActiveNodes(new Set<string>());
        } else if (activeNodes.has(clickedNode.id)){
            setActiveNodes(prev => {
                prev.delete(clickedNode.id);
                return new Set<string>(prev);
            })
        } else if (activeNodes.size < 2) {
            setActiveNodes(prev => {
                prev.add(clickedNode.id);
                return new Set<string>(prev);
            })
        } else {
            setActiveNodes(_ => {
                const newSet = new Set<string>();
                newSet.add(clickedNode.id);
                return newSet;
            })  
        }
    }

    const HandleMouseMove = (e: React.MouseEvent) => {
        if (!context) {
            return
        }
        var r = canvas.getBoundingClientRect(), x = e.clientX - r.left, y = e.clientY - r.top;
        context.clearRect(0, 0, width, height);
        let hoverNode: Node | null = null;
    
        for (let i = 0; i < nodes.length; i++) {
            if (!nodes[i].x || !nodes[i].y) {
                continue;
            }
            if ((x > nodes[i].x - RADIUS) && (y > nodes[i].y - RADIUS) && (x < nodes[i].x + RADIUS) && (y < nodes[i].y + RADIUS)) {
                hoverNode = nodes[i]
                break
            }  
        }
    
        links?.forEach((link) => {
            context.beginPath();
            context.moveTo(link.source.x, link.source.y);
            context.lineTo(link.target.x, link.target.y);
            const isActive = linkIsActive(link);
            if (isActive) {
                context.lineWidth = 3;
            } else {
                context.lineWidth = 2;
            }
            if ((hoverNode === null && activeNodes.size == 0) || isActive) {
                context.strokeStyle = strokeColor;
            } else {
                context.strokeStyle = unstrokeColor;
            }
            context.stroke();
        });
    
        nodes?.forEach((node) => {
            if (!node.x || !node.y) {
                return;
            }
            if ((hoverNode === null && activeNodes.size === 0) || (hoverNode && node.id == hoverNode.id ) || activeNodes.has(node.id)) {
                context.font = "12px Geologica";
                context.beginPath();
                context.moveTo(node.x + RADIUS, node.y);
                // context.arc(node.x, node.y, RADIUS*1.5, 0, 2 * Math.PI);
                context.arc(node.x, node.y, RADIUS, 0, 2 * Math.PI);
                context.fillStyle = color(node.index, false);
                context.fill();
                context.fillText(node.name, node.x+15, node.y-15)
            } else {
                context.font = "12px Geologica";
                context.beginPath();
                context.moveTo(node.x + RADIUS, node.y);
                context.arc(node.x, node.y, RADIUS, 0, 2 * Math.PI);
                context.fillStyle = color(node.index, true);
                context.fill();
                context.fillText(node.name, node.x+15, node.y-15)   
            }
        });
    
    };

    return { RADIUS, DrawNetwork, HandleClick, HandleMouseMove, activeNodes, activeLinks, activePaths }
}

