import { useEffect, useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { useSignup } from '../../hooks/useSignup'
import ErrorText from '../Layout/ErrorText'

export default function AuthForm({isLogin}) {
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const { signup, error: signupError, isPending: signupPending, clearError: clearSignupErr } = useSignup()
    const {login, isPending: loginPending, error: loginError, clearError: clearLoginErr } = useLogin()

    const [error, setError] = useState(null)
    
    useEffect(()=>{
        if (loginError) {
            setError(loginError)
        } else if (signupError) {
            setError(signupError)
        }
    },[loginError, signupError])

    useEffect(()=>{
        clearLoginErr()
        clearSignupErr()
        setError(null)
    },[isLogin])

    const handleSubmit = (e) => {
        setLoading(true)
        setError(null)
        e.preventDefault()
        switch (isLogin) {
            case true:
                handleLogin(e)
                break
            default:
                handleSignup(e)
        }
        setLoading(false)
    }

    const handleSignup = (e) => {
        // should require password length, special chars/nums etc...
        if (email !== confirmEmail) {
            setError('Emails do not match.')
        } else {
            signup(email, password)
        }
    
    }
    const handleLogin = (e) => {
        e.preventDefault()
        login(email, password)
    }

    return (
        <div className='authForm'>
            <form onSubmit={handleSubmit}>
                <label>
                    <span>EMAIL</span>
                    <input 
                        type="email"
                        required
                        onChange={(e)=>setEmail(e.target.value)}
                        value={email}
                    />
                </label>
                {!isLogin && (
                    <label>
                        <span>CONFIRM EMAIL</span>
                        <input 
                            type="email"
                            required
                            onChange={(e)=>setConfirmEmail(e.target.value)}
                            value={confirmEmail}
                        />
                    </label>
                )}
                <label>
                    <span>PASSWORD</span>
                    <input 
                        type="password"
                        required
                        onChange={(e)=>setPassword(e.target.value)}
                        value={password}
                        maxLength={80}
                        minLength={6}
                    />
                </label>

                {error && (
                    <> 
                        <ErrorText text={error}/>
                        <div className='smallGap'></div>
                    </>
                )}
                {loading ?  <button disabled className="btn mainButton">LOADING</button> : <button className="btn mainButton">SUBMIT</button>}
            </form>
        </div>
    )
}