import "./PostGrid.css"
import { nanoid } from "nanoid"
export default function PostGridSkeleton() {

    return (
        <div className="articles skeleton">
        {[0,1,2].map((i) => {
            return <div className="articleCard" key={nanoid()}>
            </div>
        })}
    </div>
    )
}