import './EmailForm.css'
import { useState } from 'react'
import { useAddDocument } from '../../hooks/useAddDocument'
import ErrorText from '../Layout/ErrorText'

export type emailFormProps = {
    source: string
}
export default function EmailForm({source}:emailFormProps) {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState(null)
    const {doAddDoc, err: error} = useAddDocument("/inbound/contact/mailingList")

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        doAddDoc({email, source})
        setLoading(false)
        setEmail('')
        setFeedback("[ 🙂 WOOHOO!! Thanks for tuning in! ]")
    }

    return (
        <div className='emailForm'>
            <form onSubmit={handleSubmit}>
                <label>
                    <span>EMAIL</span>
                    <input 
                        type="email"
                        required
                        placeholder='MY@EMAIL.COM'
                        onChange={(e)=>setEmail(e.target.value)}
                        value={email}
                    />
                </label>
                {loading ?  <button disabled className="btn mainButton">LOADING</button> : <button className="btn mainButton">LET'S JAM!</button>}
                {error && <><div className='smallGap'></div><ErrorText text={error}/></>}
                {(!error && feedback) && <div color='Info'><div className='smallGap'></div><p>{feedback}</p></div>}
            </form>
        </div>
    )
}