import './CommentForm.css'
import { useState } from 'react'
import ErrorText from '../Layout/ErrorText'
import RandUser from '../RandUser/RandUser'
import AuthModal from './AuthModal'
import { useAuth0 } from "@auth0/auth0-react";
import { analytics } from "../../firebase/config"
import { logEvent } from "firebase/analytics"

export default function CommentForm() {
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState(null)
    const [error, setError] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const { loginWithRedirect, user, isAuthenticated } = useAuth0();

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        setLoading(false)
        setMessage('')
    }
    const handleFocus = () => {
        logEvent(analytics, "comment_focus", {"authenticated": isAuthenticated})
        setModalOpen(true)
    }

    return (
        <div className='commentForm'>
            <AuthModal isOpen={modalOpen} setIsOpen={setModalOpen}/>
            <div className='userIconContainer'>
                <div className='userIcon'><RandUser/></div>
                <form onSubmit={handleSubmit} className='commentFormBody'>
                    <textarea
                        onChange={(e)=>setMessage(e.target.value)}
                        onFocus={handleFocus}
                        placeholder={"FEATURE COMING SOON!"}
                        maxLength={600}
                    />
                </form>
            </div>
        </div>
    )
}