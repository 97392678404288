import "./GitTreeMap.css";
import GitTreeMap from "./GitTreeMap";
import EmailForm from "../EmailForm/EmailForm";

export default function GitTreeMapWrapper({}) {

    return (
        <div className="treeMapPage">
            {/* <div className="blurOne"></div> */}
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="treeLandingContainer">
                <div className="treeTitles">
                    <h2 className="titleOne">VISUALIZE <span>YOUR</span> PROJECTS</h2>
                    <h2 className="titleThree">& BUILD MORE MEANINGFULLY</h2>
                </div>

                <GitTreeMap/>

            </div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>

            <div className="treeOverview">
                <p>
                    Introducing ✨GitMaps✨! GitMaps is a suite of visualizations and integrations that transforms your code repository into a visual landscape.
                    The mission of this project is to make software development more human, engaging, and fun.
                </p>
                <p>
                    We do this by transforming your project into a hierarchical heat map 🔥 and projecting real time information on it... enabling
                    you and your team to have more meaningful connections and conversations about the work you do 💫.
                </p>
            </div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="emailForm">
                <h2>Join the launch list!</h2>
                <EmailForm source="/gittreemap"/>
            </div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="treeMapModes">
                <div className="treeCard">
                    <h2>
                        <div className="cardIcon">
                            <span className="material-symbols-outlined">
                                done
                            </span>
                        </div>
                        SINGLE PLAYER
                    </h2>
                    <p>
                        Every engineer gets a dedicated live heatmap of their contributions. It's a lot easier to stay emotionally connected and 
                        motivated when you see the impact you make.
                    </p>
                    <p>
                        "Your contributions light up our world... literally!"
                    </p>
                </div>
                <div className="treeCard">
                    <h2>
                        <div className="cardIcon">
                            <span className="material-symbols-outlined">
                                done
                            </span>
                        </div>
                        TEAM SPORT
                    </h2>
                    <p>
                        With the team view, quickly identify experts within a region. If your team has cold zones AKA blind spots, you'll know about it.
                    </p>
                    <p>
                        "Wow the auth package has been dark for a while... maybe it's due for a review."
                    </p>
                </div>
                <div className="treeCard">
                    <h2>
                        <div className="cardIcon">
                            <span className="material-symbols-outlined">
                                done
                            </span>
                        </div>
                        CAPTURE THE FLAG
                    </h2>
                    <p>
                        Work is more engaging when you tell a story. Represent two teams in competing colors on a heatmap and watch how territories change over time.
                    </p>
                    <p>
                    "Wow, the util region just flipped blue, the integrations team must be working on something cool!"
                    </p>
                </div>
                <div className="treeCard">
                    <h2>
                        <div className="cardIcon">
                            <span className="material-symbols-outlined">
                                done
                            </span>
                        </div>
                        INTEGRATIONS
                    </h2>
                    <p>
                        Plug into your issue tracking platform to overlay bug coordinates. Integrate with your messaging systems to keep your team engaged on the ever changing landscape 
                        of your codebase.
                    </p>
                    <p>
                        "Oh no! There's a bug in the DB package. Let's talk to Jake, it looks like he's made recent contributions."
                    </p>
                </div>
            </div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
            <div className="mediumGap"></div>
        </div>
    );
}
