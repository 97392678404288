// import "../Post/Post.css"
import Post from "../../components/Post/Post"
import NotPost from "../../components/NoPost/NoPost"
import { useDocument } from "../../hooks/useDocument"
import SSHConsole from "../../components/SSHConsole/SSHConsole"
import ArticleVideo from "../../components/ArticleVideo/ArticleVideo"
import "./Staging.css"

export default function Staging() {
    const { document} = useDocument("public/articles/Staging/article")
    return (
        <div className="page blastPage">
            <div className="smallGap"></div>
            <div className="container">
                {/* <GitTreeMapWrapper/> */}
                {/* <Wasm/> */}
                <div className="article">
                {/* <TreeMap/> */}
                {/* <SSHConsole host="127.0.0.1" port={22} username="admin" password="password"/> */}
                {/* <ArticleVideo src="https://storage.googleapis.com/rebug-global-bucket/web-term-demo.mp4"/> */}
                </div>

                {document?.Author && <Post post={document} index={0}/> }
                {!(document?.Author) && <NotPost/> }
            </div>
        </div>
    )
}