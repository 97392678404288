import { nanoid } from "nanoid"
import "./OnThisPage.css"
import {Link} from 'react-scroll'

interface scrollRoute {
    tag: string
    title: string
}
interface navProps {
    routes: scrollRoute[]
}

export default function OnThisPage({routes}: navProps) {

    return (<div className="scrollRoutes">
        <h2>ON THIS PAGE</h2>
        {routes.map(r => {
            return <Link 
                key={nanoid()} 
                to={r.tag} 
                spy={true} 
                smooth={true} 
                duration={300}
                activeClass="active"
                offset={-80}
                activeStyle={{color:"var(--blue-dark)", fontWeight:"800"}}
                className={"pageRouteBtn"}>
                    {r.title.toUpperCase()}
                </Link>
        })}
    </div>)
}