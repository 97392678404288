import { Dispatch, SetStateAction } from "react"
import Modal from '../Layout/Modal'
import { useAuth0 } from "@auth0/auth0-react";

interface modalPrompts {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}
export default function AuthModal({isOpen, setIsOpen}:modalPrompts) {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    
    const handleModalConfirm = () => {
        setIsOpen(false)
        loginWithRedirect()
    }

    return (
        <div>
            {!isAuthenticated && (
                <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="🥳 Login Required" body={"You can authenticate with your existing trusted social accounts."} affirmText="Continue" onClick={handleModalConfirm}/>

            )}
            {isAuthenticated && (
                <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="🎉 You're Early!" body={"We haven't finished this feature yet but we'll notify you when we're ready!"} affirmText="OK" onClick={() => {setIsOpen(false)}}/>
            )}
        </div>
    )
}