import "./Post.css"
import { useParams } from 'react-router'
import Post from "../../components/Post/Post"
import { useBrowseContext } from "../../hooks/useBrowseContext"
import { useEffect, useState } from "react"
import EmailForm from "../../components/EmailForm/EmailForm"
import {Helmet} from "react-helmet"
import CommentSection from "../../components/CommentForm/CommentSection"
import BannerForm from "../../components/EmailForm/BannerForm"

export default function PostPage() {
    const params = useParams()
    const { documents, setFilter, setActiveArticleID, activeArticle, activeArticleID } = useBrowseContext()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (params.id) {
            setActiveArticleID(params.id)
        }
        setLoading(false)
    }, [params, documents])


    return (
        <div className="page small-blast-white">

            <Helmet>‍
                <title>Article - REBUG</title>‍
                <meta name="description" content="You're reading an article published by REBUG.DEV."/>        
            </Helmet>

            <BannerForm source={`/post/${activeArticleID}`}/>
            
            <div className="container postContainer">

                {/* <div className="smallGap"></div> */}

                { activeArticle ? <Post post={activeArticle}/> : <p className="container article"><span className="container">Fetching post...</span></p> }
                { activeArticle ? (
                    <>
                        <div className="bg-white article article-gap">
                            <div className="container">
                                <div className="mediumGap bottomLine"></div>
                                <div className="mediumGap"></div>
                            </div>
                        </div>
                        
                        <div className="emailPrompt article">
                            <div className="container medium-pd-top ">
                                <CommentSection/>
                            </div>
                        </div>
                    </>
                ) : <></>}
            </div>
        </div>
    )
}