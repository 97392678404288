import { useEffect, useRef, useState } from "react"
import { collection, query, where, onSnapshot, orderBy, limit, startAfter, getCountFromServer } from "firebase/firestore";
import { db } from "../firebase/config";

export const useCollection = (path, _where, _orderBy, _limit, _after) => {
  const [count, setCount] = useState(0)
  const [documents, setDocuments] = useState([])
  const [whereCol, whereCond, whereVal] = _where || [null, null, null]
  const [loading, setIsLoading] = useState(true)
  const __orderBy = useRef(_orderBy)

  useEffect(() => {
    let q
    // query(collection(db, path), orderBy(..._orderBy), limit(Math.min(500, _limit || 50)), where(...__where), startAfter(_after))
    if (whereCol) {
      q = query(collection(db, path), orderBy(..._orderBy), limit(10), where(whereCol, whereCond, whereVal))
    } else if (_after) {
      q = query(collection(db, path), orderBy(..._orderBy), limit(Math.min(500, _limit || 50)), startAfter(_after))
    } else {
      q = query(collection(db, path), orderBy(..._orderBy), limit(Math.min(500, _limit || 50)))
    }

    const unsubscribe = onSnapshot(q, (snapshots) => {
      setIsLoading(true)
      setDocuments(snapshots.docs.map(doc => {
        let data = doc.data()
        return {...data, Date: data.Date?.toDate(), DocID: doc.id}
      }))
      setIsLoading(false)
    })

    return unsubscribe
  }, [path, whereCol, whereCond, whereVal, __orderBy, _limit, _after])

  useEffect(() => {
    const doCount = async () => {
      const collectionRef = collection(db, path);
      const snapshot = await getCountFromServer(collectionRef)
      setCount(snapshot.data().count)
    }
    doCount()
  }, [path])
  
  return { documents, count, loading }
}