import './CommentForm.css'
import { useState } from 'react'
import ErrorText from '../Layout/ErrorText'
import CommentForm from './CommentForm'
import CommentList from '../Comment/CommentList'
import { comment } from '../Comment/Comment'
import AuthModal from './AuthModal'

const mockComments: comment[] = [
    {
        author: "JasperBot",
        date: "2023-12-31",
        likes: 2,
        body: "Great post!",
        comments: [
            {
                author: "JasperBot",
                date: "2023-12-31",
                likes: 2,
                body: "Fantastic!",
            },
            {
                author: "JasperBot",
                date: "2023-12-31",
                likes: 2,
                body: "Great post!",
            }
        ]
    },
    {
        author: "JasperBot",
        date: "2023-12-31",
        likes: 2,
        body: "@JasperBot Great post!",
    },
    {
        author: "JasperBot",
        date: "2023-12-31",
        likes: 2,
        body: "Woop!",
    }
]
export default function CommentSection() {
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState(null)
    const [error, setError] = useState()
    const [modalOpen, setModalOpen] = useState(false)

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        setLoading(false)
        setMessage('')
    }

    return (
        <div className='commentSection'>
            <AuthModal isOpen={modalOpen} setIsOpen={setModalOpen}/>
            <div className='commentHeader'>
                <h2 className='subTitle'>FORUM</h2>
                <div className='formButton'>
                    {loading ?  <button className="greyButton">LOADING</button> : <button className="greyButton" onClick={()=>setModalOpen(true)}>POST IT</button>}
                </div>
                {error && <><div className='smallGap'></div><ErrorText text={error}/></>}
                {(!error && feedback) && <div color='Info'><div className='smallGap'></div>{feedback}</div>}
            </div>
            <CommentForm/>
            <hr></hr>
            <CommentList comments={mockComments} reply={true}/>
        </div>
    )
}