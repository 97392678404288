export function isStrongPwd(password) {
    var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!?@#$%&*()]).{8,}/
    var validPassword = regExp.test(password)
    return validPassword
  }

  Array.prototype.random = function () {
    return this[Math.floor((Math.random()*this.length))];
  }

export const randSmileys = [":-)",":-o",">:-D",";-P",":^)",":-T","X-)",":-D","B-)",":-U",";-D",">:-]"]

export const emojiOrder = ["👋","🔥","🤯","✨","👍","🥳","🤩","🔥","🤙"]
export const colors = ["red", "orange", "yellow", "green", "blue", "purple"]


export const doHash = (text) => {
  let hash = 0;
  for (let i = 0, len = text.length; i < len; i++) {
      let char = text.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
  }
  return Math.abs(hash);
}