import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // collection, getDocs,
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// https://firebase.google.com/docs/web/setup

const firebaseConfig = {
    apiKey: "AIzaSyCQZvSMqJZMAwG-fY_dJvC21trq5VhRzSI",
    authDomain: "rebut-116bb.firebaseapp.com",
    projectId: "rebut-116bb",
    storageBucket: "rebut-116bb.appspot.com",
    messagingSenderId: "368808254989",
    appId: "1:368808254989:web:45fdb7b829e5147aa8bf44",
    measurementId: "G-NKF29BT07P"
}

// init firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, auth, analytics }