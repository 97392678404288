import "./PostFooter.css"
import { useBrowseContext } from "../../hooks/useBrowseContext"
import { useNavigate } from "react-router-dom"
import AuthModal from "../CommentForm/AuthModal"
import { useState } from "react"
import { analytics } from "../../firebase/config"
import { logEvent } from "firebase/analytics"

export default function PostFooter() {
    const { activePrevID, activeNextID, activeArticleID } = useBrowseContext()
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false)

    const handleClick = (dir) => {
        if (dir === "prev" && activePrevID) {
            navigate(`/post/${activePrevID}`)
        } else if (dir === "next" && activeNextID) {
            navigate(`/post/${activeNextID}`)
        }
    }

    const handleLike = () => {
        logEvent(analytics, "like_post", {"post_id": activeArticleID})
        setModalOpen(true)
    }

    return (<div className="postFooter">
        <AuthModal isOpen={modalOpen} setIsOpen={setModalOpen}/>
        <div className="mediumGap"></div>
        <div className="mediumGap topLine"></div>
        <div className="links">
            <button onClick={() => {handleClick("prev")}} className={"postNavButton " + (activePrevID || "disabled")}>
                    <span className="material-symbols-outlined previous">
                        double_arrow
                    </span>
            </button>
            {/* <button to="#" className="info disabled">
                    <span className="material-symbols-outlined">
                        help
                    </span>
            </button> */}
            <button onClick={() => {handleClick("next")}} className={"postNavButton " + (activeNextID || "disabled")}>
                    <span className="material-symbols-outlined">
                        double_arrow
                    </span>
            </button>

            <button className="postNavButton likeButton" onClick={handleLike}>
                    <span className="material-symbols-outlined">
                        thumb_up
                    </span>
            </button>
        </div>
    </div>)
}