import { useEffect, useRef } from "react";
import "./TreeMap.css";
import * as d3 from "d3";
import { useDimensions } from "../../hooks/useDimension";
var json = require("./mock-data.json");

// Approximate text dimensions
const PTPX = 0.75;
const FNTSIZE = 20;
const AVGLTRWDTH = 0.95;

type Node = {
    title: string;
    weight: number;
    value: number;
    icon: string;
    children: Node[];
};

export default function TreeMap({}) {
    const containerRef = useRef(null);
    const { width, height } = useDimensions(containerRef);

    useEffect(() => {
        // Clear
        d3.selectAll("svg > *").remove();

        const hierarchy = d3
            .hierarchy(json)
            .sum((d: Node) => d.value)
            .sort((a, b) => b.value - a.value);
        const treemap = d3.treemap().size([width, height]).padding(2);
        const root = treemap(hierarchy);

        // Global hover classes
        const svg = d3
            .select("svg")
            .classed("mouse-out", true)
            .on("mouseover", function () {
                d3.select(this).classed("mouse-out", false);
            })
            .on("mouseout", function () {
                d3.select(this).classed("mouse-out", true);
            });
        
        // Plot our grid
        svg.selectAll("rect")
            .data(root.leaves())
            .enter()
            .append("rect")
            .attr("x", (d) => d.x0)
            .attr("y", (d) => d.y0)
            .attr("width", (d) => d.x1 - d.x0)
            .attr("height", (d) => d.y1 - d.y0)
            .attr("fill", (d: d3.HierarchyRectangularNode<Node>) => {
                if ([1, 2, 3].includes(d.data.value)) {
                    return "var(--grid-blue-lightest)";
                } else if ([4, 5, 6].includes(d.data.value)) {
                    return "var(--grid-blue-light)";
                } else if (d.data.value === 7) {
                    return "var(--grid-blue-mid)";
                } else if (d.data.value === 8) {
                    return "var(--grid-blue-dark)";
                } else {
                    return "var(--grid-blue-darkest)";
                }
            }) // Hover classes for inner rects
            .on("mouseover", function () {
                d3.select(this).classed("mouse-hover", true);
            })
            .on("mouseout", function () {
                d3.select(this).classed("mouse-hover", false);
            });

        // Add text
        svg.selectAll("title")
            .data(root.leaves())
            .enter()
            .append("text")
            .attr("x", function (d) {
                return d.x0 + 5;
            })
            .attr("y", function (d) {
                return d.y0 + 20;
            })
            .text(function (d: d3.HierarchyRectangularNode<Node>) {
                return d.data.title;
            })
            .attr("font-size", `${FNTSIZE}px`)
            .attr("fill", "white")
            .attr("font-family", "Roboto Mono")
            .attr("font-weight", "600")
            .style("opacity", (d: d3.HierarchyRectangularNode<Node>) => {
                if (
                    d.data.title.length * AVGLTRWDTH * PTPX * FNTSIZE >=
                    d.x1 - d.x0
                ) {
                    return 0;
                }
                return 1;
            }) // Maintain hover effect
            .style("pointer-events", "none");

        // Second title
        svg.selectAll("info")
            .data(root.leaves())
            .enter()
            .append("text")
            .attr("x", function (d) {
                return d.x0 + 5;
            })
            .attr("y", function (d) {
                return d.y0 + 45;
            })
            .text(function (d: d3.HierarchyRectangularNode<Node>) {
                return `${d.data.value} \\ ${d.data.weight}`;
            })
            .attr("font-size", `16px`)
            .attr("fill", "white")
            .attr("font-family", "Roboto Mono")
            .style("pointer-events", "none")
            .style("opacity", (d: d3.HierarchyRectangularNode<Node>) => {
                if (
                    d.data.title.length * AVGLTRWDTH * PTPX * FNTSIZE >=
                    d.x1 - d.x0
                ) {
                    return 0;
                }
                return 1;
            });

        // Icons
        svg.selectAll("icon")
            .data(root.leaves())
            .enter()
            .append("text")
            .attr("x", function (d) {
                return d.x0 + (d.x1 - d.x0 - 45) / 2;
            })
            .attr("y", function (d) {
                return d.y0 + (d.y1 - d.y0 + 30) / 2;
            })
            .text((d: d3.HierarchyRectangularNode<Node>) => d.data.icon)
            .attr("font-size", `45px`)
            .attr("fill", "white)")
            .attr("font-family", "Roboto Mono")
            .style("pointer-events", "none")
            .style("opacity", (d: d3.HierarchyRectangularNode<Node>) => {
                if (d.data.icon) {
                    return 1;
                }
                return 0;
            });
    }, [width, height]);

    return (
        <div className="tree-container">
            <div className="tree-map" ref={containerRef}>
                <svg></svg>
            </div>
        </div>
    );
}
