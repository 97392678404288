import { nanoid } from "nanoid";
import CodeBox from "../CodeBox/CodeBox";
import PostFooter from "./PostFooter";
import OnThisPage from "./OnThisPage";
import PagerFeed from "../PagerFeed/PagerFeed";
import ArticleImage from "../ArticleImage/ArticleImage";
import ArticleVideo from "../ArticleVideo/ArticleVideo";
import "./Post.css";
import { useEffect, useState } from "react";
import { NetworkWrapper } from "../Graph/NetworkWrapper";
import CounterBadge from "../CountBadge/CounterBadge";
import { NavLink } from "react-router-dom";
import Interact from "./Interact";
import HashForm from "../RandUser/HashForm";
import TreeMap from "../TreeMap/TreeMap";

const componentRegistry = {
    pagerFeed: <PagerFeed />,
    schematic: <NetworkWrapper />,
    hashForm: <HashForm/>,
    treeMap: <TreeMap/>
};

const constructElement = (elem) => {
    switch (elem.Type) {
        case "h1":
            return (
                <h1 key={nanoid()} className="articleTitle">
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </h1>
            );
        case "h2":
            return (
                <h2
                    key={nanoid()}
                    id={
                        typeof elem.Content === "string"
                            ? elem.Content.replaceAll(" ", "_")
                            : nanoid()
                    }
                    className="articleTitle"
                >
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </h2>
            );
        case "nav":
            return (
                <div key={nanoid()} id={elem.Tag} className="articleSection">
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </div>
            );
        case "d":
            return (
                <div key={nanoid()} className={elem.Class || ""}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </div>
            );
        case "h3":
            return (
                <h3 key={nanoid()} className="articleTitle">
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </h3>
            );
        case "p":
            return (
                <p key={nanoid()} className={elem.Class || ""}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </p>
            );
        case "inlinep":
            // TODO deprecate this.
            return (
                <p key={nanoid()}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) =>
                              constructElement(
                                  e.Type === "code" ? { ...e, Type: "c" } : e
                              )
                          )}
                </p>
            );
        case "ol":
            return (
                <ol key={nanoid()}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </ol>
            );
        case "ul":
            return (
                <ul key={nanoid()}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </ul>
            );
        case "li":
            return (
                <li key={nanoid()}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </li>
            );
        case "code":
            return (
                <CodeBox
                    key={nanoid()}
                    code={
                        typeof elem.Content === "string"
                            ? elem.Content
                            : elem.Content.map((e) => constructElement(e))
                    }
                    language={elem.Language}
                    abbreviated={elem.Abbreviated}
                />
            );
        case "c":
            return (
                <code className="inlineCode" key={nanoid()}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </code>
            );
        case "a":
            return (
                <a key={nanoid()} href={elem.href} className="articleLink">
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </a>
            );
        case "component":
            return (
                <div key={nanoid()} className="articleComponentContainer">
                    {componentRegistry[elem.Content]}
                </div>
            );
        case "img":
            return (
                <div key={nanoid()} className="articleImageContainer">
                    <ArticleImage src={elem.src} alt={elem.alt} />
                </div>
            );
        case "vid":
            return (
                <div key={nanoid()}>
                    <ArticleVideo src={elem.src} />
                </div>
            );
        case "b":
            return (
                <strong key={nanoid()}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </strong>
            );
        case "i":
            return (
                <em key={nanoid()}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </em>
            );
        default:
            return (
                <span key={nanoid()} className={elem.Class || ""}>
                    {typeof elem.Content === "string"
                        ? elem.Content
                        : elem.Content.map((e) => constructElement(e))}
                </span>
            );
    }
};

export default function Post({ post }) {
    const [pageRoutes, setPageRoutes] = useState([]);
    useEffect(() => {
        const routes = [];
        post.Content.forEach((element, i) => {
            if (element.Type === "h2" && typeof element.Content === "string") {
                routes.push({
                    tag: element.Content.replaceAll(" ", "_"),
                    title: element.Content,
                });
            } else if (element.Type === "nav") {
                routes.push({ tag: element.Tag, title: element.Title });
            }
        });
        setPageRoutes(routes);
    }, [post]);

    return (
        <div className="postArticle article">
            <div className="container">
                <Interact category={post.Category}/>
                <OnThisPage routes={pageRoutes} />
                <div className="articleHeader">
                    <ul className="postTags">
                        {post.Tags.map((tag, i ) => (
                            <li key={nanoid()}>
                                {`#${tag}`}
                                <span className="divider">{i !== post.Tags.length - 1 ? "|" : ""}</span>
                            </li>
                        ))}
                    </ul>
                    <h1 className="bigTitle">{post.Title}</h1>
                    <div className="headerText">
                        <div className="authorInfo">
                            <div className="authorAvatar">A</div>
                            <div className="authorNameDate">
                                <div className="authorName">
                                    {post.Author.toUpperCase()}
                                </div>
                                <div className="authorDate">
                                    {post.Date.toISOString().slice(0, 10)}
                                    <NavLink to="/join" className={"subscribe"}>Follow</NavLink>
                                </div>
                            </div>
                            <div className="interactionIcons">
                                <CounterBadge icon="thumb_up" count={1} style="flex" size="small"/>
                                <span className="divider">{" / "}</span>
                                <CounterBadge icon="chat_bubble" count={"0"} style="flex" size="small"/>
            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="articleBody">
                    {post.Content.map((elem) => constructElement(elem))}
                </div>
                <PostFooter />
            </div>
        </div>
    );
}
