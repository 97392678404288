import "./LandingHero.css"

export default function LandingHero({latestArticleID, handleClick}) {

    return (
        <div className="landingHeroSection">
            <div className="container landingContainer">
                <div className="heroTitle">
                    <h1 className="bigTitle">SHARE</h1>
                    <h1 className="bigTitle darkTitle">LEARN</h1>
                    <h1 className="bigTitle">GROW</h1>
                    <h2 className="secondTitle">✨REBUG.DEV, a notebook of software exloration 🚀.</h2>
                </div>
                {/* <div className="heroRight">{":-)"}</div> */}
                <div className="buttons">
                    <button className="joinButton" onClick={() => handleClick("/join")}>JOIN</button>
                    <button className="secondButton" onClick={() => handleClick(`/articles`)}>BLOG</button>
                </div>
            </div>
            <div></div>
        </div>
    )
}