import { BrowseContext } from "../context/BrowseContext"
import { useContext } from "react"

export const useBrowseContext = () => {
  const context = useContext(BrowseContext)

  if(!context) {
    throw Error('useBrowseContext must be used inside a BrowseContextProvider')
  }

  return context
}