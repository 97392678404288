import { Schema, Constraint } from "../components/Graph/Table";
import { useState, useEffect, useLayoutEffect } from "react";
import { tableSchemas } from "../components/Graph/data";
import { Node, Link } from "../util/GraphNode";

interface GraphData {
    nodes: Node[]
    links: Link[]
}

export const useRelationalData = () => {

    const [schemas, setSchemas] = useState<Schema[]>((): Schema[] => {
        // let s: Schema[] 
        return tableSchemas
    })
    const [graphData, setGraphData] = useState<GraphData>()

    useEffect(() => {
        const data: GraphData = {
            nodes: [],
            links: [],
        }
        schemas?.forEach((schema, i) => {
            data.nodes.push({
                id: schema.TableName,
                name: schema.TableName,
                index: i
            })
            schema.Constraints.forEach(cons => {
                data.links.push({
                    source: schema.TableName,
                    target: cons.ReferencedTableName,
                    value: cons.ConstraintName
                })
            })
        })
        setGraphData(data)
    }, [schemas])

    const schemaToQuery = (schemaForm: Schema[]) => {
        if ((schemaForm?.length || 0) === 0) {
            return
        }
        let selectedFields: string[] = []
        let sqlQuerySelect = "SELECT "
        let sqlQueryJoin = "\nFROM `" + schemaForm[0].TableName + "`"
        const seen = new Set<string>()
        schemaForm.forEach((s, i) => {
            selectedFields = [...selectedFields, ...s.Fields.filter(f => f.Selected).map(f => "`"+s.TableName+"`.`"+f.ColumnName+"`")]
            const shouldSkip = seen.has(s.TableName)
            let constraint: Constraint
            if (!shouldSkip && i > 0) {
                // do we have a constraint to the previous table>
                constraint = s.Constraints.find(c => c.ReferencedTableName === schemaForm[i-1].TableName)
                if (constraint) {
                    sqlQueryJoin += "\nJOIN `"+`${s.TableName}`+"`\n\tON "+"`"+`${s.TableName}`+"`.`"+constraint.ColumnName+"` = `"+constraint.ReferencedTableName+"`.`"+constraint.ReferencedColumnName+"`"
                }
            }
            if (i < schemaForm.length - 1) {
                // do we have a constraint to the next table?
                constraint = s.Constraints.find(c => c.ReferencedTableName === schemaForm[i+1].TableName)
                if (constraint) {
                    seen.add(constraint.ReferencedTableName)
                    sqlQueryJoin += "\nJOIN `"+`${constraint.ReferencedTableName}`+"`\n\tON "+"`"+`${constraint.ReferencedTableName}`+"`.`"+constraint.ReferencedColumnName+"` = `"+s.TableName+"`.`"+constraint.ColumnName+"`"
                }
            }

        })
        if (selectedFields.length > 0) {
            sqlQuerySelect += selectedFields.join(", ")
        } else {
            return ""
        }
        // given a set of tables and selected fields, construct SQL statement
        return sqlQuerySelect+sqlQueryJoin+";"
    }

    return {schemas, graphData, schemaToQuery}
}