import "./Badge.css"

interface badgeProps {
    text: string
    color: string
    size: string
}
export default function Badge({text, color, size}:badgeProps) {
    return (
        <div className={`badge ${color || "purple"} ${size || "medium"}`}>
            {text}
        </div>
    )
}