import "./About.css"
import About from "../../components/Layout/About"
import unicorn from "../../assets/rpdsh-thck.svg"
import {Helmet} from "react-helmet"

export default function AboutRoute() {
    return (
        <div className="page blastPage container aboutPage">

            <Helmet>‍
                <title>About - REBUG</title>‍
                <meta name="description" content="Learn about REBUG.DEV and our mission statement."/>        
            </Helmet>

            <div className="article aboutContainer">
                <div className="smallGap"></div>
                <div className="brand"><img src={unicorn} alt={"R"}></img><span className="brandText"> REBUG.DEV</span></div>
                <About/>
            </div>
        </div>
    )
}